const outermost = 0;
// 当一个open api有多种访问方式时，只上报一次
export class ReportContext {
    flag = { level: outermost };
    enterReport() {
        this.flag.level = this.flag.level + 1;
    }
    leaveReport() {
        this.flag.level = this.flag.level - 1;
    }
    shouldReport() {
        return this.flag.level === outermost;
    }
}
