import { onBeforeUnmount, onMounted, ref } from '@vue/composition-api';
export const getHost = (host) => {
    if (typeof host === 'function') {
        return host();
    }
    return ref(host).value;
};
export function useEvents(host, eventMap) {
    const handleEvents = (handler) => {
        Object.entries(eventMap).forEach(([key, options]) => {
            [options].flat().forEach((option) => {
                if (typeof option === 'function') {
                    handler?.(key, option);
                }
                else {
                    const { handler: eventHandler, options } = option;
                    handler?.(key, eventHandler, options);
                }
            });
        });
    };
    onMounted(() => {
        const hostDom = getHost(host);
        handleEvents(hostDom?.addEventListener.bind(hostDom));
    });
    onBeforeUnmount(() => {
        const hostDom = getHost(host);
        handleEvents(hostDom?.removeEventListener.bind(hostDom));
    });
}
