import { ref } from '@vue/composition-api';
import { throttle } from 'lodash';
import { Mouse } from '@/utils/mouse';
import { useEvents } from './useEvents';
export function useDrag(dragHostRef, { onStart, onEnd, onMove, throttleTime = 50, }) {
    const dragging = ref(false);
    let context;
    let startX = 0;
    let startY = 0;
    const throttleOnMove = throttle(onMove, throttleTime);
    const getInfo = (e) => {
        const info = { diffX: Math.round(e.x) - startX, diffY: Math.round(e.y) - startY };
        return info;
    };
    const stop = (e) => {
        if (!dragging.value) {
            return;
        }
        if (!Mouse.clickedLeft(e)) {
            return;
        }
        onEnd?.(context, getInfo(e));
        dragging.value = false;
    };
    useEvents(dragHostRef, {
        pointerdown: (e) => {
            if (!Mouse.clickedLeft(e)) {
                return;
            }
            startX = Math.round(e.x);
            startY = Math.round(e.y);
            context = onStart?.();
            dragging.value = true;
            e.currentTarget.setPointerCapture(e.pointerId);
        },
        mousemove: (e) => {
            if (!dragging.value) {
                return;
            }
            throttleOnMove(context, getInfo(e));
        },
        mouseup: stop,
    });
    return dragging;
}
