import { get, isFunction, isNil, isString } from 'lodash';
import { defaultSchema } from './type';
import { omitEmpty } from '@/utils/object';
const defaultOptions = {
    getFieldInfo: f => (isString(f) ? { name: f } : f),
    priority: 0,
};
const prioritySet = (map, key, data, priority) => {
    const prev = map.get(key);
    const assignOrder = [prev?.data, data];
    if (!isNil(prev?.priority) && priority < prev?.priority) {
        assignOrder.reverse();
    }
    const mergedData = Object.assign({}, ...assignOrder);
    map.set(key, { data: mergedData, priority });
};
class SchemaContainer {
    fieldMap = new Map();
    schemaMap = new Map();
    register = (schema, options = defaultOptions) => {
        prioritySet(this.schemaMap, schema.id, this.parseSchema(schema), options.priority ?? defaultOptions.priority);
        this.extends(schema, options);
    };
    extends = (schema, options = defaultOptions) => {
        const mergedOpts = Object.assign({}, defaultOptions, options);
        schema.fields && Object.entries(schema.fields).forEach(([field, info]) => {
            const key = this.combineKey(schema.id, field);
            this.deepRegister(key, info, mergedOpts);
        });
    };
    getFieldDetailInfo = (schemaId, fieldPath) => this.fieldMap.get(this.combineKey(schemaId, fieldPath))?.data;
    getSchemaInfo = (schemaId) => this.schemaMap.get(schemaId)?.data;
    deepRegister = (parentKey, field, opts) => {
        prioritySet(this.fieldMap, parentKey, this.parseField(opts.getFieldInfo(field)), opts.priority);
        !isString(field) && field.details
            && Object.entries(field.details)?.forEach(([subKey, subField]) => {
                this.deepRegister(`${parentKey}.${subKey}`, subField, opts);
            });
    };
    parseExtends = (exs) => {
        const identityFields = exs.identityField ? [exs.identityField].flat(1) : [];
        return {
            getIdentity: identityFields.length
                ? (getter) => {
                    const mergedGet = isFunction(getter) ? getter : (path) => get(getter, path);
                    const mergedGetIdentity = exs.getIdentity ?? defaultSchema.getIdentity;
                    return mergedGetIdentity?.(...identityFields.map(path => mergedGet(path)));
                }
                : undefined,
        };
    };
    parseSchema = (declareSchema) => {
        const fieldControl = declareSchema;
        return omitEmpty({
            id: declareSchema.id,
            name: declareSchema.name,
            type: declareSchema.type,
            fieldUncheckable: declareSchema.fieldUncheckable,
            pickFields: fieldControl.pickFields,
            omitFields: fieldControl.omitFields,
            pairs: declareSchema.pairs,
            ...this.parseExtends(declareSchema),
        });
    };
    parseField = (declareField) => {
        const schemaExtends = this.parseExtends(declareField);
        return omitEmpty({
            name: declareField.name,
            type: declareField.type,
            uncheckable: declareField.uncheckable,
            detailUncheckable: declareField.detailUncheckable,
            specialUncheckable: declareField.specialUncheckable,
            lang: declareField.lang,
            diffPlaceholder: declareField.diffPlaceholder,
            pairs: declareField.pairs,
            weak: declareField.weak,
            idField: declareField.idField,
            format: declareField.format,
            ...schemaExtends,
        });
    };
    combineKey(schemaId, fieldPath) {
        return `${schemaId}:${[fieldPath].flat(1).join('.')}`;
    }
}
export const schemaContainer = new SchemaContainer();
// 避免流水线脚本执行失败
try {
    const ctx = require.context('./schemas', false, /\.ts$/);
    ctx.keys().forEach((key) => {
        const { schema } = ctx(key);
        if (!schema) {
            throw Error(`${key} not export schema`);
        }
        schemaContainer.register(schema, { priority: 1 });
    });
}
catch (error) {
    console.log(error);
}
