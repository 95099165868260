import { defineComponent, ref } from '@vue/composition-api';
import useJobService from '@composables/flow/useJobService';
import RelativeTime from '@/workbench/components/relative-time';
// 这个文件好像没有上线，没找到入口
const RelatedJobList = defineComponent({
    name: 'RelatedJobList',
    props: {
        // todo(ice): common route to fetch job list?
        projectId: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        jobQuery: {
            type: Object,
            default: {},
        },
    },
    setup(props) {
        const projectId = props.projectId || window.RUNTIME_CONFIGS.projectId;
        const envId = window.RUNTIME_CONFIGS.env;
        const { relatedListForRuntime, countRelatedListForRuntime, allForRuntime, countAllForRuntime, } = useJobService({ projectId, envId });
        const jobList = ref([]);
        const pagination = ref({
            current: 1,
            pageSize: 10,
            total: 10,
        });
        const loading = ref(false);
        const fetchJobList = async (type, opt) => {
            loading.value = true;
            const resp = type === 'all'
                ? await allForRuntime(opt)
                : await relatedListForRuntime(type, opt);
            const total = type === 'all'
                ? await countAllForRuntime(opt.filter, opt.query)
                : await countRelatedListForRuntime(type, opt.filter, opt.query);
            if (opt.page)
                pagination.value.current = opt.page;
            pagination.value.total = total;
            jobList.value = resp;
            loading.value = false;
        };
        const onPageChange = (info) => {
            pagination.value.pageSize = info.pageSize;
            pagination.value.current = info.current;
            fetchJobList(props.type, { query: props.jobQuery, page: info.current, size: info.pageSize });
        };
        return {
            loading,
            pagination,
            projectId,
            envId,
            fetchJobList,
            onPageChange,
            jobList,
        };
    },
    watch: {
        type: {
            handler(val) {
                this.fetchJobList(val, { page: 1, query: this.jobQuery });
            },
            immediate: true,
        },
        jobQuery: {
            handler(val) {
                this.fetchJobList(this.type, { page: 1, query: val });
            },
            immediate: true,
            deep: true,
        },
    },
    render() {
        const columns = [
            {
                title: '名称',
                key: 'title',
                dataIndex: 'title',
            },
            {
                title: '状态',
                key: 'status',
                dataIndex: 'status',
            },
            {
                title: '消息',
                key: 'message',
                dataIndex: 'message',
            },
            {
                title: '审批人',
                key: 'currentApprovers',
                dataIndex: 'currentApprovers',
            },
            {
                title: '申请人',
                key: 'creator',
                dataIndex: 'creator',
            },
            {
                title: '申请时间',
                key: 'ctime',
                dataIndex: 'ctime',
                customRender: (text) => <RelativeTime time={text}/>,
            },
            {
                title: '操作',
                customRender: (txt, row) => <span>
            <router-link to={{ path: `/wuji/workflow/flow/${encodeURIComponent(row.flowId)}`, query: { jobId: row.id } }}>
              查看
            </router-link>
        </span>,
            },
        ];
        return <div>
      <a-table columns={columns} row-key="id" pagination={this.pagination} loading={this.loading} data-source={this.jobList} onChange={this.onPageChange}>
      </a-table>
    </div>;
    },
});
export default RelatedJobList;
