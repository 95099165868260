export { DragPosition } from '@utils/tdesign/tree';
export var UrlType;
(function (UrlType) {
    UrlType["Page"] = "page";
    UrlType["Link"] = "link";
})(UrlType || (UrlType = {}));
export var MenuType;
(function (MenuType) {
    MenuType["Menu"] = "menu";
    MenuType["Link"] = "link";
})(MenuType || (MenuType = {}));
export var TargetType;
(function (TargetType) {
    TargetType["Self"] = "self";
    TargetType["Blank"] = "blank";
})(TargetType || (TargetType = {}));
// eslint-disable-next-line @typescript-eslint/naming-convention
export const menuTypeOptions = [
    {
        label: '目录',
        value: MenuType.Menu,
        color: '#fef9c3',
    },
    {
        label: '链接',
        value: MenuType.Link,
        color: '#dbeafe',
    },
];
export const allOption = { value: '*', label: '所有' };
