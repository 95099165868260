import { useBetterCreatableSelect } from '@/hooks/useBetterCreatableSelect';
import { useStore } from '@/store';
import { getUUID } from '@/utils/common';
import { computed, getCurrentInstance, ref } from '@vue/composition-api';
export const ENV_TAG_SEPARATOR = '/';
function buildTagOption(obj, option, paths, formatOption) {
    const keys = Object.keys(obj);
    if (keys.length) {
        Object.assign(option, { children: option.children ?? [] });
    }
    Object.keys(obj).forEach((key) => {
        const curPaths = paths.concat(key);
        option.children?.push(buildTagOption(obj[key], { label: key, value: curPaths.join(ENV_TAG_SEPARATOR) }, curPaths, formatOption));
    });
    return !option.root && formatOption ? formatOption(option) : option;
}
;
export function getTagOptions(tags, formatOption) {
    const map = {};
    tags.forEach((tag) => {
        if (tag) {
            tag.split(ENV_TAG_SEPARATOR).reduce((obj, path) => {
                if (!obj[path]) {
                    Object.assign(obj, { [path]: {} });
                }
                return obj[path];
            }, map);
        }
    });
    return (buildTagOption(map, { value: '', label: '', root: true }, [], formatOption).children ?? []);
}
;
const succMsg = { result: true, message: '' };
const refId = `selectRef_${getUUID()}`;
export function useEnvTag({ getTag, setTag }) {
    const instance = getCurrentInstance();
    const hostRef = computed(() => instance?.proxy.$refs?.[refId]);
    const store = useStore();
    const createdTags = ref([]);
    const envs = computed(() => store.state.env.envList);
    const tags = computed(() => envs.value.map(env => env.tag).filter(Boolean));
    const tagOptions = computed(() => [...new Set((tags.value ?? []).concat(createdTags.value))].map(tag => ({ label: tag, value: tag })));
    const onChange = (tag) => {
        setTag(String(tag));
    };
    const selectListener = useBetterCreatableSelect({
        onCreate: (tag) => {
            createdTags.value.push(tag);
            onChange(tag);
        },
        hostRef,
    });
    const tagRule = [{
            validator: (val) => {
                if (!val?.length) {
                    return succMsg;
                }
                if ([val[0], val[val.length - 1]].includes(ENV_TAG_SEPARATOR)) {
                    return { result: false, message: `环境标签不能由分隔符 ${ENV_TAG_SEPARATOR} 开头或结尾` };
                }
                if (val.split(ENV_TAG_SEPARATOR).some((tag) => !tag.trim())) {
                    return { result: false, message: `两个分割符 ${ENV_TAG_SEPARATOR} 之间不能为空` };
                }
                return succMsg;
            },
        }];
    const EnvTag = () => <t-form-item label="环境标签" name="tag" help="通过标签对环境进行聚合，例如标签为a/b的环境在环境选择页面会被聚合到a/b目录下">
    <t-select ref={refId} value={getTag()} onChange={onChange} onCreate={(val) => {
            createdTags.value.push(String(val));
        }} placeholder='请选择或输入标签' options={tagOptions.value} onBlur={selectListener.onBlur} onEnter={selectListener.onEnter} onInput-change={selectListener.onInputChange} filterable creatable clearable/>
  </t-form-item>;
    return {
        tagRule,
        EnvTag,
    };
}
