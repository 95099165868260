import { appendHandler, getGroupNameMap, removeHandler, setGroupId } from '@/components/sidebar/utils';
import useLayout from '@/composables/useLayout';
import { message } from '@/utils/wuji-message';
import { computed, getCurrentInstance, nextTick, ref, watch } from '@vue/composition-api';
import { uniq } from 'lodash';
import { sidebarOpenApiBatchWrap } from '../util';
export function useCommonOpenApi({ onMenuChange, onKeysChange, }) {
    const { curGroup, visibleSidebarGroups, visibleGroupMap } = useLayout();
    // 使menus成为响应式变量
    const reactiveRef = ref(curGroup.value);
    watch(curGroup, v => (reactiveRef.value = v));
    const instance = getCurrentInstance();
    const groupNameMap = computed(() => getGroupNameMap(visibleSidebarGroups.value));
    // Sidebar 实例供外部调用的方法
    /**
     * [Open-Api]添加菜单项
     *
     * @param menu 菜单Item，
     * @example
     *  {
     *    args: { target: 'self', url: '/path1' },
     *    name: '新路径',
     *    type: 'link'
     *  }
     * @param parentName 一级菜单的name
     * @param repeatBlock name重复时是否阻止添加
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    const append = (menu, parentName, options) => appendHandler(curGroup.value?.menus, menu, parentName, options);
    /**
     * [Open-Api]删除指定菜单
     *
     * @param position 菜单name
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    const remove = (position) => removeHandler(curGroup.value?.menus, position);
    /**
     * [Open-Api]跳转到指定菜单
     *
     * @param position 菜单name
     * @param groupName 菜单组name
     * @return {boolean} true 表示执行成功, false 表示执行失败
     */
    const goto = (position, groupName) => {
        const res = groupNameMap.value?.[groupName || curGroup.value.groupName]?.[position];
        if (res?.menu) {
            onMenuChange(res.menu);
        }
        return instance?.proxy;
    };
    /**
     * [Open-Api]设置当前菜单组
     *
     * @param position 菜单组 name / id
     * @example
     *   设置一级菜单: first
     * @return {Object} 当前组件实例
     */
    const setCurrentGroup = (position, { tips = true } = {}) => {
        let currentSidebar = visibleSidebarGroups.value.find(group => `${group.groupName}` === `${position}`);
        if (!currentSidebar) {
            currentSidebar = visibleGroupMap.value[position];
        }
        if (currentSidebar) {
            setGroupId(currentSidebar.groupId);
        }
        else if (tips) {
            message.warning(`Sidebar.setCurrentGroup: 找不到 ${position} 菜单组`);
        }
        return instance?.proxy;
    };
    /**
     * [Open-Api]设置当前高亮的菜单项
     *
     * @param position 菜单name
     * @example
     *   设置一级菜单: first
     *   设置二级菜单: first.second
     *   设置三级菜单: first.second.third
     */
    const setCurrent = (position) => {
        nextTick(() => {
            const res = groupNameMap.value?.[curGroup.value.groupName]?.[position];
            if (res) {
                const { openKeys = [], selectedKeys = [] } = res;
                onKeysChange(prev => ({
                    expandKeys: uniq([...prev.expandKeys, ...openKeys]),
                    selectedKeys,
                }));
            }
        });
    };
    return sidebarOpenApiBatchWrap({ append, remove, goto, setCurrentGroup, setCurrent });
}
