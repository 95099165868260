import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { provideSidebarContext } from '../hooks/useData';
import styles from './index.module.scss';
import { debounce, throttle } from 'lodash';
import { filterSearchMenus, getAllOpenKeys } from '@/components/sidebar/utils';
import { useState } from '../hooks/useState';
import { getMenuChildren, sidebarOpenApiBatchWrap } from '../util';
import classNames from 'classnames';
import { useDrag } from '@/hooks/useDrag';
import CollapseTrigger from '@components/sidebar/CollapseTrigger.vue';
import { useEvents } from '@/hooks/useEvents';
import { useCommonOpenApi } from '../hooks/useCommonOpenApi';
import { shallowObj } from '@/utils/object';
import { useCollapsed } from '../hooks/useCollapsed';
export const WindowSideBar = defineComponent({
    name: 'WindowSideBar',
    setup() {
        const searchOpenKeys = ref([]);
        const context = provideSidebarContext({
            formatter: {
                sidebar: ({ sidebarMap, groupId }) => sidebarMap[groupId],
                menus: ({ sidebar }) => sidebar?.menus,
            },
        });
        const searchValue = ref('');
        const { menus, sidebar, sidebarTheme, wLayout, options, isDarkTheme, currentGroupId } = context;
        const width = ref(sidebar.value.width);
        watch(sidebar, () => {
            searchValue.value = '';
            width.value = sidebar.value.width;
        });
        const { onMenuChange, onExpandChange, getExpandKeys, expandKeys, selectedKeys, renderIdentity } = useState();
        const filteredMenus = ref(menus.value);
        watch(menus, (cur) => {
            if (!searchValue.value) {
                filteredMenus.value = cur;
            }
        });
        watch(searchValue, (cur, prev) => {
            if (cur && !prev) {
                searchOpenKeys.value = expandKeys.value;
            }
        });
        const showSearch = computed(() => sidebar.value.withSearch);
        watch(searchValue, debounce(() => {
            const cur = filterSearchMenus(menus.value, searchValue.value);
            filteredMenus.value = cur;
            searchOpenKeys.value = getAllOpenKeys(cur);
        }, 200));
        const onMergedExpandChange = (keys) => {
            if (searchValue.value) {
                searchOpenKeys.value = getExpandKeys(keys, searchOpenKeys.value);
            }
            else {
                onExpandChange(keys);
            }
        };
        const getValidWidth = (originWidth) => {
            const defaultWidth = sidebar.value.width;
            const max = defaultWidth * 1.5;
            if (originWidth > defaultWidth) {
                if (originWidth > max) {
                    return max;
                }
                return originWidth;
            }
            if (originWidth < defaultWidth) {
                return defaultWidth;
            }
            return defaultWidth;
        };
        const containerStyle = computed(() => {
            const renderWidth = collapsed.value ? 0 : width.value;
            return { width: `${renderWidth}px` };
        });
        const dragHost = ref();
        useDrag(dragHost, {
            onStart: () => width.value,
            onMove: (startWidth, { diffX }) => {
                width.value = getValidWidth(startWidth + diffX);
            },
        });
        const mergedExpandKeys = computed(() => (searchValue.value ? searchOpenKeys.value : expandKeys.value));
        const menuTree = computed(() => getMenuChildren(filteredMenus.value, shallowObj({
            expandKeySet: new Set(mergedExpandKeys.value),
            showIcons: sidebar.value.showIcons ?? [true, false],
            options,
        })));
        const showCollapseTrigger = ref(false);
        // showCollapseTrigger和antd逻辑保持一致
        const { collapsed, setCollapsed, toggleCollapsed } = useCollapsed({
            init: false,
            onCollapseChange: () => (showCollapseTrigger.value = true),
        });
        useEvents(window, {
            mousemove: throttle((e) => {
                const { clientX } = e;
                const menuWidth = collapsed.value ? 0 : width.value;
                showCollapseTrigger.value = clientX < menuWidth + 30;
            }, 100),
        });
        const toHome = () => {
            window.open('https://xy.woa.com/xy/app/prod/portal/index');
        };
        const openApi = useCommonOpenApi({
            onMenuChange,
            onKeysChange(updater) {
                const cur = updater(shallowObj({ expandKeys, selectedKeys }));
                expandKeys.value = cur.expandKeys;
                selectedKeys.value = cur.selectedKeys;
            },
        });
        return {
            menuTree,
            menus,
            isDarkTheme,
            searchValue,
            sidebarTheme,
            dragHost,
            // 运行时会用
            collapsed,
            showCollapseTrigger,
            containerStyle,
            // open api
            currentGroupId,
            ...(sidebarOpenApiBatchWrap({
                setCollapsed,
                toggleCollapsed,
            })),
            innerToggleCollapsed: toggleCollapsed,
            innerSetCollapsed: setCollapsed,
            onMenuChange,
            onMergedExpandChange,
            renderIdentity,
            toHome,
            selectedKeys,
            mergedExpandKeys,
            showSearch,
            width,
            wLayout,
            ...openApi,
        };
    },
    render() {
        if (!this.menus?.length) {
            return <div></div>;
        }
        const identity = this.renderIdentity();
        return (<div style={this.containerStyle} class={classNames(styles.container, this.sidebarTheme, { [styles.dark]: this.isDarkTheme })}>
        {!this.collapsed && this.showSearch && (<t-input clearable placeholder="搜索" v-model={this.searchValue} class={styles.search} prefixIcon={() => <t-icon name="search"/>}/>)}
        <t-menu expanded={this.mergedExpandKeys} value={this.selectedKeys[0]} onExpand={this.onMergedExpandChange} onChange={this.onMenuChange} class={styles.menu} theme={this.sidebarTheme}>
          {this.menuTree}
        </t-menu>
        {!this.collapsed && identity && (<div onClick={this.toHome} class={styles.identity}>
            {identity}
          </div>)}
        <div class={styles.dragHost} ref={'dragHost'}></div>
        <CollapseTrigger isShowCollapseTrigger={this.showCollapseTrigger} onToggle-collapsed={this.innerToggleCollapsed} collapsed={this.collapsed}/>
      </div>);
    },
});
