/* eslint-disable @typescript-eslint/indent */
import { getTagOptions } from '@/workbench/pages/deployment/publish-setting/components/useEnvTag';
import { computed } from '@vue/composition-api';
import { groupBy, keyBy } from 'lodash';
const defaultOptions = { contentKey: 'content', getEnvValue: env => String(env.id) };
function envsToOptions(envs, opts) {
    return envs?.map(env => ({
        [opts.contentKey]: env.name,
        value: opts.getEnvValue(env),
        env,
    })) ?? [];
}
export const optionToDropdownItem = (option, renderItem) => {
    if (option.children?.length) {
        const children = option.children.map(child => optionToDropdownItem(child, renderItem));
        return <t-dropdown-menu>
      {option.content}
      {/* 如果只有一个子目录需要包裹一层 menu 否则会命中TDesign的bug （src/dropdown/hooks/useDropdownOptions.ts） */}
      {option.children.length > 1 ? children : <t-dropdown-menu>{children}</t-dropdown-menu>}
    </t-dropdown-menu>;
    }
    return renderItem(option);
};
export function useEnvOptions(getEnvs, opts) {
    const mergedOpts = Object.assign({}, defaultOptions, opts);
    const envs = computed(getEnvs);
    const envMap = computed(() => keyBy(envs.value, mergedOpts.getEnvValue));
    const taggedEnvs = computed(() => envs.value.filter(env => env.tag));
    const hasTagEnv = computed(() => Boolean(taggedEnvs.value.length));
    const nonTaggedEnvs = computed(() => envs.value.filter(env => !env.tag));
    const envTagGroups = computed(() => groupBy(envs.value, 'tag'));
    const envToOption = (env) => envsToOptions([env], mergedOpts)[0];
    const options = computed(() => getTagOptions(taggedEnvs.value
        .map(env => env.tag), option => ({
        [mergedOpts.contentKey]: option.label,
        value: option.value,
        children: (option.children ?? [])
            .concat(envsToOptions(envTagGroups.value[option.value], mergedOpts)),
    }))
        .concat(envsToOptions(nonTaggedEnvs.value, mergedOpts)));
    return { options, taggedEnvs, nonTaggedEnvs, envMap, hasTagEnv, envToOption };
}
