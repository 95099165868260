import { useEnvOptions } from '@/workbench/components/preview-button/useTagEnvOptions';
import { ENV_TAG_SEPARATOR } from '@/workbench/pages/deployment/publish-setting/components/useEnvTag';
import { computed, defineComponent } from '@vue/composition-api';
import classNames from 'classnames';
import styles from './tag-env-select.module.scss';
import { isMobileOrNarrowScreen } from '@/utils/screen';
export const TagEnvSelect = defineComponent({
    name: 'RunTimeTagEnvSelect',
    props: {
        envList: {
            type: Array,
            required: true,
        },
        theme: {
            type: String,
        },
        env: {
            type: Object,
        },
        visible: {
            type: Boolean,
        },
    },
    emits: {
        change: (_env) => void 0,
        'update:visible': (_visible) => void 0,
    },
    setup(props, { emit }) {
        const { options, hasTagEnv, taggedEnvs, nonTaggedEnvs, envToOption } = useEnvOptions(() => props.envList);
        const envPaths = computed(() => {
            const items = (props.env?.tag?.split(ENV_TAG_SEPARATOR) ?? []).filter(Boolean);
            const paths = [];
            if (items.length) {
                paths.push(items.reduce((prev, cur) => {
                    paths.push(prev);
                    const merged = [prev, cur].join(ENV_TAG_SEPARATOR);
                    return merged;
                }));
            }
            return paths;
        });
        const resetCls = computed(() => classNames(styles.styleReset, props.theme));
        const overlayClassName = computed(() => classNames(`env-select-popover ${props.theme} ${resetCls.value}`, styles.popover, {
            [styles.hasTag]: hasTagEnv.value,
        }));
        const isMobile = computed(isMobileOrNarrowScreen);
        const isSelected = (option) => {
            const { env } = props;
            if (!env?.env) {
                return false;
            }
            const isTarget = !option.children?.length && option.env?.env === env?.env;
            const isPath = option.children?.length && envPaths.value.includes(option.value);
            return Boolean(isTarget || isPath);
        };
        const getDataActive = (option) => +isSelected(option);
        const renderFolder = (option) => (<a-menu data-active={getDataActive(option)} theme={props.theme}>
        <a-sub-menu popupClassName={classNames(resetCls.value)} key={option.value} title={option.content}>
          {option.children?.map(renderSubMenu)}
        </a-sub-menu>
      </a-menu>);
        const renderItem = (option) => (<p onClick={() => emit('change', option.env)} data-active={getDataActive(option)} class={classNames('env-select_options', {
                ['env-select_options-active']: isSelected(option),
            })} key={option.value}>
        {option.content}
      </p>);
        const renderSubMenu = (option) => {
            if (option.children?.length) {
                return (<a-sub-menu data-active={getDataActive(option)} popupClassName={classNames(resetCls.value)} key={option.value} title={option.content}>
            {option.children.map(renderSubMenu)}
          </a-sub-menu>);
            }
            return <a-menu-item>{renderItem(option)}</a-menu-item>;
        };
        const distributeContentRender = (option) => {
            if (option.children?.length) {
                return renderFolder(option);
            }
            return renderItem(option);
        };
        const contentRender = () => (isMobile.value
            ? nonTaggedEnvs.value.concat(taggedEnvs.value).map(env => renderItem(envToOption(env)))
            : options.value.map(distributeContentRender));
        const innerVisible = computed({
            get() {
                return props.visible;
            },
            set(val) {
                emit('update:visible', val);
            },
        });
        return { options, contentRender, overlayClassName, innerVisible };
    },
    render() {
        return (<a-popover v-model={this.innerVisible} overlay-class-name={classNames(this.overlayClassName)} trigger="click" scopedSlots={{
                content: this.contentRender,
            }}>
        {this.$slots.default}
      </a-popover>);
    },
});
