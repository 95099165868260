import { computed } from '@vue/composition-api';
import { useStore } from '@store/index';
import { keyBy } from 'lodash';
import { getDefaultGroup, getGroupId } from '@/components/sidebar/utils';
export default function useLayout() {
    const store = useStore();
    const visibleSidebarGroups = computed(() => store.getters?.['layout/visibleSidebarGroups']);
    const visibleGroupMap = computed(() => keyBy(visibleSidebarGroups.value, group => group.groupId));
    const navSelectGroupId = computed(() => getGroupId());
    const curGroup = computed(() => visibleGroupMap.value[navSelectGroupId.value] ?? getDefaultGroup());
    return {
        // 渲染器实例
        mainRenderer: computed(() => store.state.layout.mainRenderer),
        project: computed(() => store.getters?.['layout/project']),
        roles: computed(() => store.state.layout.roles),
        biz: computed(() => store.state.layout.biz),
        isAdmin: computed(() => store.state.layout.isAdmin),
        navTheme: computed(() => store.getters?.['layout/navTheme']),
        sidebarGroups: computed(() => store.getters?.['layout/sidebarGroups']),
        visibleSidebarGroups,
        visibleGroupMap,
        navSelectGroupId,
        curGroup,
        sidebarTheme: computed(() => store.getters?.['layout/sidebarTheme']),
        customSidebar: computed(() => store.state.layout.customSidebar),
        // 顶部导航
        showSidebarGroups: computed(() => store.getters?.['layout/showSidebarGroups']),
        navShowSidebarGroups: computed(() => store.getters?.['layout/navShowSidebarGroups']),
        navSidebarGroupSelectedKeys: computed(() => store.getters?.['layout/navSidebarGroupSelectedKeys']),
    };
}
