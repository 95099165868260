import { set, forEach, get } from 'lodash';
import { message, Modal, notification } from 'ant-design-vue';
import {
  XyModalSelectorMeta, XyPageletPropsEditorMeta, XyGlobalModalSettingMeta,
} from '../paramsFormComponents';
import { stringInterop, vueDeepSet } from '@tencent/ui-core/lib/utils';
import { toPathParts } from '@tencent/data-schema-core/lib/utils/path';
import { toString } from '@/utils/toString';
import { CATEGORY } from './const';
import pageLoader from '@loaders/page/loader';

// 关闭弹窗
export const getXyHideModalAtom = type => ({
  id: 'xy:hideModal',
  name: '关闭弹窗',
  category: CATEGORY.UI,
  visible: type === 'modal',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'data',
        type: 'array',
        title: '返回参数 (在打开弹窗行为接收)',
        'uc:allowInterop': true,
        ui: {
          type: 'XyPageletPropsEditor',
          props: {
            keyIsInteropable: true,
          },
        },
        items: {
          type: 'object',
          fields: [
            { id: 'key', type: 'string' },
            { id: 'value', type: 'string' },
          ],
        },
      },
    ],
  },
  paramsFormComponents: [
    XyPageletPropsEditorMeta,
  ],
  async execute(ctx, params) {
    const { wContext, w } = ctx.renderer;  // xy 上下文
    const { data } = params;
    const hideData = {};
    if (Array.isArray(data)) {
      data.forEach((item) => {
        const { key, value } = item;
        hideData[key] = value;
      });
    }

    if (wContext?.type === 'globalModal') {
      await w.hideGlobalModal(wContext.id, hideData);
    } else {
      await wContext.hideModal(null, hideData);
    }
  },
});
// 弹出消息窗口
export const alertAtom = {
  id: 'uicore:alert',
  name: '弹出消息窗口',
  category: 'UI交互',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'text',
        type: 'string',
        title: '文案',
        default: '请设置文案',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
      {
        id: 'icon',
        type: 'string',
        title: '图标',
        enum: [
          { value: '', label: '无图标' },
          { value: 'info' },
          { value: 'success' },
          { value: 'error' },
          { value: 'warning' },
        ],
      },
      {
        id: 'width',
        type: 'string',
        title: '宽度(可选)',
        description: '示例： 300px',
      },
    ],
  },
  execute: async (ctx, params) => {
    const text = toString(params.text).trim();
    if (!text) throw new Error('请设置文案');

    return new Promise((resolve) => {
      const modalOption = {
        content: h => h('div', { style: { maxWidth: '80vw', whiteSpace: 'pre-wrap' } }, [text]),
        maskClosable: true,
        onOk: () => void resolve(),
        onCancel: () => void resolve(),
      };
      if (params.width) set(modalOption, 'width', params.width);
      // 无图标则渲染空的 VNode, 否则使用 Modal 方法对应的图标
      if (!params.icon) modalOption.icon = h => h();
      Modal[params.icon || 'info'](modalOption);
    });
  },
};
// 弹出确认框
export const confirmAtom = {
  id: 'uicore:confirm',
  name: '弹出确认框',
  category: CATEGORY.UI,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'text',
        type: 'string',
        title: '文案 (支持HTML)',
        default: '真的要执行操作吗？',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
      {
        id: 'abortIfRejected',
        type: 'boolean',
        title: '选择了“否”就终止流程',
        default: true,
      },
      {
        id: 'okText',
        type: 'string',
        title: '确定按钮文字',
        default: '确定',
      },
      {
        id: 'cancelText',
        type: 'string',
        title: '取消按钮文字',
        default: '取消',
      },
      {
        id: 'icon',
        title: '图标',
        type: 'string',
        enum: [
          { value: 'question' },
          { value: 'question-circle' },
          { value: 'info' },
          { value: 'info-circle' },
          { value: 'check' },
          { value: 'check-circle' },
          { value: 'exclamation' },
          { value: 'exclamation-circle' },
          { value: 'close' },
          { value: 'close-circle' },
          { value: 'stop' },
          { value: 'warning' },
          { value: 'delete' },
        ],
        default: 'question-circle',
      },
      {
        id: 'centered',
        title: '垂直居中展示',
        type: 'boolean',
        default: false,
      },
    ],
  },
  returnValue: { type: 'boolean', description: '用户确认结果' },
  execute: async (ctx, params) => {
    const { text: content, okText, cancelText, icon, centered = false, abortIfRejected } = params;
    const confirmed = await new Promise((resolve) => {
      Modal.confirm({
        content: () => <div domPropsInnerHTML={toString(content)}></div>,
        okText: toString(okText),
        cancelText: toString(cancelText),
        icon: toString(icon),
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        centered,
      });
    });
    if (!confirmed && abortIfRejected) ctx.abort();
    return confirmed;
  },
};
// 弹出提示
export const messageAtom = {
  id: 'uicore:message',
  name: '弹出提示',
  category: CATEGORY.UI,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        title: '提示类型',
        type: 'string',
        enum: [
          { value: 'info' },
          { value: 'error' },
          { value: 'success' },
          { value: 'warn' },
          { value: 'loading' },
        ],
        default: 'info',
      },
      {
        id: 'duration',
        title: '提示持续时间',
        type: 'integer',
        default: 3,
      },
      {
        id: 'isSync',
        title: '是否为同步提示',
        type: 'boolean',
        default: false,
      },
      {
        id: 'text',
        type: 'string',
        title: '提示内容',
        default: '请设置内容',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
    ],
  },
  execute: async (ctx, params) => {
    const { type = 'info', duration = 3, isSync = false } = params;
    const text = toString(params.text);
    const content = h => h('span', {
      style: {
        display: 'inline-block',
        'white-space': 'pre-wrap',
        'vertical-align': 'top',
        'text-align': 'left',
      },
    }, [text]);
    const promise = message[type](content, duration);

    if (isSync) await promise;
  },
};
// 弹出通知框
export const notificationAtom = {
  id: 'uicore:notification',
  name: '弹出通知框',
  category: CATEGORY.UI,
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'type',
        title: '提示类型',
        type: 'string',
        enum: [
          { value: 'open' },
          { value: 'error' },
          { value: 'success' },
          { value: 'warn' },
          { value: 'error' },
        ],
        default: 'open',
      },
      {
        id: 'placement',
        title: '弹出位置',
        type: 'string',
        enum: [
          { value: 'topLeft', label: '左上方' },
          { value: 'topRight', label: '右上方' },
          { value: 'bottomLeft', label: '左下方' },
          { value: 'bottomRight', label: '右下方' },
        ],
        default: 'topRight',
      },
      {
        id: 'duration',
        title: '提示持续时间',
        type: 'number',
        default: 4.5,
      },
      {
        id: 'message',
        type: 'string',
        title: '通知标题',
        default: '通知标题',
      },
      {
        id: 'description',
        type: 'string',
        title: '通知内容',
        default: '请设置通知内容',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
    ],
  },
  execute: async (ctx, params) => {
    const { type = 'info', placement, message, description, duration, isSync } = params;

    const options = {
      placement,
      message: () => <div domPropsInnerHTML={toString(message)}></div>,
      description: () => <div domPropsInnerHTML={toString(description)}></div>,
      duration,
      isSync,
    };

    notification[type](options);
  },
};

export const promptAtom = {
  id: 'uicore:prompt',
  name: '弹出文字输入框',
  category: 'UI交互',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'message',
        type: 'string',
        title: '提示消息',
        default: '请输入一些东西：',
        ui: {
          settings: {
            multipleLine: true,
          },
        },
      },
      {
        id: 'defaultValue',
        type: 'string',
        title: '默认值',
      },
      // 兼容旧字段, 当现存勾选了 password 时, 外显配置让用户反选后再切换输入框模式
      {
        id: 'password',
        type: 'boolean',
        title: '密码模式',
        condition: '!!params.password',
      },
      {
        id: 'inputType',
        type: 'string',
        title: '输入框模式',
        enum: [
          { value: 'input', label: '单行文本' },
          { value: 'textarea', label: '多行文本' },
          { value: 'password', label: '密码' },
        ],
      },
      {
        id: 'allowEmpty',
        type: 'boolean',
        title: '允许空字符串',
      },
      {
        id: 'abortIfRejected',
        type: 'boolean',
        title: '选择了“取消”就终止流程（否则会使用默认值）',
        default: true,
      },
    ],
  },
  returnValue: { type: 'string', description: '用户输入的文本' },
  execute: async (ctx, params) => {
    const { uc } = ctx;
    while (true) {
      // 兼容旧字段 password 优先级最高
      const password = !!params.password || params.inputType === 'password';
      const textarea = params.inputType === 'textarea';

      const answer = await uc?.ui?.prompt({
        message: toString(params.message),
        defaultValue: toString(params.defaultValue),
        password,
        textarea,
      });

      if (answer === null) {
        if (params.abortIfRejected) ctx.abort();
        return params.defaultValue;
      }

      if (answer === '' && !params.allowEmpty) continue;

      return answer;
    }
  },
};

// 打开弹窗
export const showModalAtom = {
  id: 'xy:showModal',
  name: '打开弹窗',
  category: CATEGORY.UI,
  desc: '弹窗关闭后, 才执行后续步骤',
  paramsSchema: {
    type: 'object',
    fields: [
      {
        id: 'modalId',
        type: 'string',
        title: '选择弹窗',
        ui: { type: 'XyModalSelector' },
      },
      {
        id: 'globalModalSetting',
        type: 'object',
        title: '全局弹窗设置',
        ui: { type: 'XyGlobalModalSetting' },
        default: {
          keepAlive: false,
          suffix: '',
        },
        condition: 'params.modalId.includes(":")',
      },
      {
        id: 'data',
        type: 'array',
        title: '弹窗参数 (弹窗内通过 props.xxx 取参)',
        ui: {
          type: 'XyPageletPropsEditor',
          props: {
            allowBind: true,
            async getPagelet(inputBox) {
              const { modalId } = inputBox.container.stub.parent.value;
              const isModalPage = modalId?.includes(':');
              if (isModalPage) {
                const [, pageId] = modalId.split(':');
                const { projectId } = inputBox.wContext;
                const { pageConfig: pagelets } = await pageLoader.getPageDetail({
                  projectId,
                  pageId,
                });
                return pagelets?.find(x => x.id === 'default');
              }
              const allModalPagelets = inputBox.wContext.modalList;
              return allModalPagelets.find(x => x.id === modalId);
            },
          },
        },
        items: {
          type: 'object',
          fields: [
            { id: 'key', type: 'string' },
            { id: 'value', type: 'string' },
            { id: 'bind', type: 'string' },
          ],
        },
        'uc:allowExpr': false,
      },
    ],
  },
  paramsFormComponents: [
    XyModalSelectorMeta,
    XyPageletPropsEditorMeta,
    XyGlobalModalSettingMeta,
  ],
  async execute(ctx, params) {
    const { renderer } = ctx;
    const { wContext, w } = renderer;  // xy 上下文
    // [废弃] mergeParams 兼容旧配置
    const { modalId, data, mergeParams, globalModalSetting } = params;
    const dynamicData = {};
    const propsData = {};

    const mixedContext = new Proxy(renderer, {
      get(t, k) {
        if (k === 'flow') return ctx;
        if (k === 'flowState') return ctx.state;
        if (k === '$event') return ctx.$event;

        return Reflect.get(t, k);
      },
    });

    forEach(data, (paramItem) => {
      const { key, value: valueRaw, bind } = paramItem;

      if (bind) {
        // 使用双向绑定的方式结合到当前页面的数据源
        const bindParts = toPathParts(bind);
        Object.defineProperty(dynamicData, key, {
          configurable: false,
          enumerable: true,
          get: () => get(mixedContext, bindParts),
          set: (value) => {
            vueDeepSet(mixedContext, bindParts, value);
          },
        });
      } else {
        // 值传递
        Object.defineProperty(dynamicData, key, {
          configurable: false,
          enumerable: true,
          get: () => {
            if (Object.prototype.hasOwnProperty.call(propsData, key)) return propsData[key];
            return stringInterop(valueRaw, renderer, { flow: ctx, flowState: ctx.state, $event: ctx.$event });
          },
          set: (value) => {
            propsData[key] = value;
          },
        });
      }
    });

    const isModalPage = modalId?.includes(':');
    const [, pageId] = modalId?.split(':');
    const closeRes = isModalPage
      ? await w.showGlobalModal(pageId, dynamicData, globalModalSetting)
      : await wContext.showModal(modalId, dynamicData, { mergeParams, params: data });
    return closeRes;
  },
  returnValue: {
    description: '弹窗关闭行为的返回值',
  },
};

export default [
  alertAtom,
  confirmAtom,
  messageAtom,
  notificationAtom,
  promptAtom,
  showModalAtom,
];
