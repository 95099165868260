import { isRef } from '@vue/composition-api';
import { isNil, omitBy } from 'lodash';
export function omitEmpty(data) {
    return omitBy(data, isNil);
}
export function shallowObj(origin) {
    return Object.fromEntries(Object.entries(origin).map(([key, val]) => (isRef(val) ? [key, val.value] : [key, val])));
}
export const buildMap = (array, getKey, formatValue) => {
    if (!array?.length) {
        return {};
    }
    return Object.assign({}, ...array.map(v => ({ [getKey(v)]: formatValue ? formatValue(v) : v })));
};
export const stringifyOrErrorMsg = (val) => {
    try {
        return JSON.stringify(val);
    }
    catch (err) {
        return JSON.stringify(err);
    }
};
