import { ref, watch } from '@vue/composition-api';
import { getSidebarContext } from './useData';
import { getAllOpenKeys, getConfigOpenKeys, getMatchedMenuKeys, getMenuUrl, onActiveMenuChange } from '@/components/sidebar/utils';
import { difference, isObject, once, union } from 'lodash';
import { toPageByMenu } from '@/utils/path';
import { isRootMenuKey } from '../util';
import { SIDEBAR_IDENTITY, useRenderIdentity } from './useRenderIdentity';
import { MenuType } from '@/workbench/pages/project/navigation-menu/menu/type';
export function useState() {
    const { options, sidebar, menus, getMenu } = getSidebarContext();
    const selectedKeys = ref([]);
    const expandKeys = ref([]);
    const init = once(() => {
        if (sidebar.value.openAll) {
            expandKeys.value = union(getAllOpenKeys(menus.value), expandKeys.value);
        }
        else if (sidebar.value.accordion) {
            expandKeys.value = union(getConfigOpenKeys(menus.value), expandKeys.value);
        }
    });
    watch(menus, (cur) => {
        const { openKeys, selectedKeys: curSelectedKeys, activeMenu, parentMenus, } = getMatchedMenuKeys(cur, options.value);
        if (sidebar.value.accordion) {
            expandKeys.value = union(openKeys, expandKeys.value.filter(key => !isRootMenuKey(key)));
        }
        else {
            expandKeys.value = union(openKeys, expandKeys.value);
        }
        init();
        selectedKeys.value = curSelectedKeys;
        onActiveMenuChange({ activeMenu, parentMenus, path: options.value.$route?.fullPath });
    }, { immediate: true });
    const onMenuChange = (val) => {
        const menu = isObject(val) ? val : getMenu(val);
        const { key, type } = menu;
        if (type === MenuType.Menu) {
            return;
        }
        if (key && selectedKeys.value.includes(key)) {
            if (getMenuUrl(menu) === options.value.$route?.path) {
                return;
            }
        }
        toPageByMenu(menu, options.value);
    };
    const getExpandKeys = (curKeys, prevKeys) => {
        let nextExpandKeys = curKeys.map(String);
        if (sidebar.value.accordion) {
            const nextRootKeys = nextExpandKeys.filter(isRootMenuKey);
            const prevRootKeys = prevKeys.filter(isRootMenuKey);
            const diffExpandRootKeys = difference(nextRootKeys, prevRootKeys);
            if (diffExpandRootKeys.length) {
                nextExpandKeys = nextExpandKeys.filter(key => !isRootMenuKey(key)).concat(diffExpandRootKeys);
            }
        }
        return nextExpandKeys;
    };
    const onExpandChange = (keys) => {
        expandKeys.value = getExpandKeys(keys, expandKeys.value);
    };
    const showIdentity = useRenderIdentity();
    const renderIdentity = () => (showIdentity.value ? SIDEBAR_IDENTITY : false);
    return {
        onMenuChange,
        onExpandChange,
        getExpandKeys,
        expandKeys,
        selectedKeys,
        renderIdentity,
    };
}
