import { ref } from '@vue/composition-api';
import { isBoolean } from 'lodash';
export function useCollapsed({ init, onCollapseChange }) {
    const collapsed = ref(init);
    // openApi
    const setCollapsed = (value) => {
        if (isBoolean(value)) {
            // 和antd逻辑保持一致
            collapsed.value = value;
            onCollapseChange?.(value);
        }
    };
    // open api
    const toggleCollapsed = () => {
        setCollapsed(!collapsed.value);
    };
    return {
        collapsed,
        setCollapsed,
        toggleCollapsed,
    };
}
