import { computed } from '@vue/composition-api';
export const SIDEBAR_IDENTITY = 'Powered by 无极';
export function useRenderIdentity() {
    return computed(() => {
        const { open, openDeployType, externalDeploy } = window?.GLOBAL_INFO || {};
        // 外部商业化环境
        if (externalDeploy)
            return false;
        // 当前在内网环境下
        if (!open)
            return true;
        // 在open版中，但是是在内网环境下
        if (open && openDeployType === 'designer')
            return true;
        // 外网环境统一不渲染
        return false;
    });
}
