import { computed, defineComponent } from '@vue/composition-api';
import styles from './index.module.scss';
import { provideSidebarContext } from '../hooks/useData';
import { MenuType } from '@/workbench/pages/project/navigation-menu/menu/type';
import { getMenuChildren, sidebarOpenApiBatchWrap } from '../util';
import { shallowObj } from '@/utils/object';
import { useState } from '../hooks/useState';
import { useCollapsed } from '../hooks/useCollapsed';
import classNames from 'classnames';
import { useCommonOpenApi } from '../hooks/useCommonOpenApi';
import { runtimeSidebarActionReport } from '../../tool';
import { isMiniprogram } from '@/utils/screen';
export const MobileSideBar = defineComponent({
    name: 'MobileSideBar',
    setup() {
        const context = provideSidebarContext({
            formatter: {
                sidebar: ({ origin }) => {
                    // 只有一个菜单组则不展示菜单组
                    if (origin.length <= 1) {
                        return origin[0];
                    }
                    return {
                        // 这些值不会被实际用到
                        groupId: 'virtual',
                        groupName: 'virtualGroup',
                        width: window.innerWidth,
                        // 这些值会实际用到
                        accordion: true,
                        openAll: true,
                        showIcons: [false, true, false],
                        menus: origin.map(sidebar => ({
                            name: sidebar.groupName,
                            type: MenuType.Menu,
                            args: {
                                icon: '',
                                open: true,
                                menus: sidebar.menus,
                            },
                        })),
                    };
                },
                menus: ({ sidebar }) => sidebar?.menus,
            },
        });
        const { menus, sidebar, options, sidebarTheme, isDarkTheme, currentGroupId } = context;
        const { collapsed, setCollapsed, toggleCollapsed } = useCollapsed({ init: true });
        const { onMenuChange, expandKeys, selectedKeys } = useState();
        const menuTree = computed(() => getMenuChildren(menus.value, shallowObj({
            expandKeySet: new Set(expandKeys.value), showIcons: sidebar.value.showIcons ?? [true, false], options, onClickMenu: () => {
                setCollapsed(true);
            },
        })));
        const openApi = useCommonOpenApi({
            onMenuChange, onKeysChange(updater) {
                const cur = updater(shallowObj({ expandKeys, selectedKeys }));
                expandKeys.value = cur.expandKeys;
                selectedKeys.value = cur.selectedKeys;
            },
        });
        runtimeSidebarActionReport('tdesign-mobile', { isMini: isMiniprogram() });
        return {
            menuTree,
            expandKeys,
            onMenuChange,
            ...(sidebarOpenApiBatchWrap({
                setCollapsed,
                toggleCollapsed,
            })),
            innerToggleCollapsed: toggleCollapsed,
            innerSetCollapsed: setCollapsed,
            selectedKeys,
            sidebarTheme,
            // 运行时会用
            collapsed,
            isDarkTheme,
            // open api
            currentGroupId,
            ...openApi,
        };
    },
    render() {
        return <t-drawer visible={!this.collapsed} placement='top' preventScrollThrough footer={false} header={false} class={classNames(styles.drawer, this.sidebarTheme)} showOverlay={false}>
      <div class={styles.container}>
        <t-menu expandMutex value={this.selectedKeys[0]} onChange={this.onMenuChange} class={classNames(styles.menu, this.sidebarTheme, { [styles.dark]: this.isDarkTheme })} theme={this.sidebarTheme}>
          {this.menuTree}
        </t-menu>
      </div>
    </t-drawer>;
    },
});
