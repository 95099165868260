import { setGroupId } from '@/components/sidebar/utils';
import store from '@/store';
import { getCurrentInstance, onBeforeMount, onBeforeUnmount } from '@vue/composition-api';
export function useSyncToStore(mode) {
    const instance = getCurrentInstance();
    onBeforeMount(() => {
        store.commit('layout/setSidebar', instance?.proxy);
        store.commit('layout/setLayoutMode', mode);
        if (instance?.proxy.wLayout) {
            Object.assign(instance.proxy.wLayout, { sidebar: instance.proxy });
        }
    });
    onBeforeUnmount(() => {
        store.commit('layout/removeSidebar', instance?.proxy);
        setGroupId('');
    });
}
