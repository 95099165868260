import { message as AntdMessage } from 'ant-design-vue';
import { MessagePlugin as TDesignMessagePlugin } from 'tdesign-vue';
import { isNil } from 'lodash';
const DURATION = {
    message: 3000,
    notification: 3000,
};
const useTDesignMessage = () => location.href.includes('/workbench/');
const TDesignMessage = (type) => {
    const wrappedMessageMethod = (originalMethod) => function (...args) {
        const [content, duration, onClose] = args;
        // antd 的duration是秒
        const tdesignDuration = !isNil(duration) ? duration * 1000 : DURATION.message;
        if (typeof content === 'object') {
            content.duration = content.duration
                ? content.duration * 1000
                : DURATION.message;
            return originalMethod.apply(this, args);
        }
        if (typeof onClose === 'function') {
            return originalMethod.apply(this, [
                {
                    content,
                    duration: tdesignDuration,
                    onClose,
                },
            ]);
        }
        // 保持和antd一致，返回一个关闭方法
        const instancePromise = originalMethod.apply(this, [
            content,
            tdesignDuration,
        ]);
        return () => {
            instancePromise.then((instance) => {
                instance.close();
            });
        };
    };
    return wrappedMessageMethod(TDesignMessagePlugin[type]);
};
const getWujiMessage = (type) => {
    if (useTDesignMessage()) {
        return TDesignMessage(type);
    }
    return AntdMessage[type];
};
const wrapWujiConfig = (configParam) => {
    if (useTDesignMessage()) {
        // antd的config参数中的top和maxCount在tdesign中没有对应的参数
        // 这个场景我们只做duration和attach的转换
        const tdesignParams = {
            duration: DURATION.message,
            attach: 'body',
        };
        tdesignParams.duration = configParam.duration
            ? configParam.duration * 1000
            : DURATION.message;
        tdesignParams.attach = configParam.getContainer ? configParam.getContainer : 'body';
        return TDesignMessagePlugin.config(tdesignParams);
    }
    return AntdMessage.config(configParam);
};
const wujiMessage = function (params) {
    const { message: content, type, iconClass, duration, onClose } = params;
    if (useTDesignMessage()) {
        return TDesignMessage(type)(content, duration, onClose);
    }
    AntdMessage[type]({
        content,
        icon: iconClass,
        duration,
    }).then(onClose, onClose);
};
wujiMessage.success = getWujiMessage('success');
wujiMessage.warning = getWujiMessage('warning');
wujiMessage.warn = getWujiMessage('warning');
wujiMessage.info = getWujiMessage('info');
wujiMessage.error = getWujiMessage('error');
wujiMessage.loading = getWujiMessage('loading');
wujiMessage.open = AntdMessage.open; // tdesign没有open方法
wujiMessage.config = wrapWujiConfig;
export default wujiMessage;
