const CONDITION_BRANCH = 'uicore:if';
/**
 * 遍历layout中的events
 * @param events 某个组件layout 中的events字段
 * @param handler 处理函数，会获得step和context这两个参数，如果需要提前终止**当前事件**的遍历，可以通过函数返回true达成
 * @param branchIncluded 是否需要遍历“条件分支”
 */
export const walkThroughEventStep = (events, handler, { branchIncluded }) => {
    Object.keys(events).forEach((eventName) => {
        const event = events[eventName];
        const stepContext = {
            eventName,
            isInBranch: false,
        };
        event.steps.some((step) => {
            const res = handler(step, stepContext);
            if (step.type === CONDITION_BRANCH && branchIncluded) {
                const { branches } = step.params;
                branches.forEach((branch) => {
                    const { condition, disabled, expanded, steps } = branch;
                    const branchStepContext = {
                        ...stepContext,
                        isInBranch: true,
                        branchParams: {
                            condition,
                            disabled,
                            expanded,
                        },
                    };
                    steps.some(step => handler(step, branchStepContext));
                });
            }
            return res;
        });
    });
};
