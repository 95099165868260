import { inject, provide } from '@vue/composition-api';
export function defineContext(createContext) {
    const token = Symbol();
    const provideContext = (...args) => {
        const context = createContext(...args);
        provide(token, context);
        return context;
    };
    const mountContext = (getMountContext) => {
        const context = inject(token);
        context && Object.assign(context, getMountContext());
    };
    const getContext = () => inject(token);
    return [provideContext, mountContext, getContext];
}
