import { stringifyOrErrorMsg } from '@/utils/object';
import designerReport from './instance/designerReport';
import { delay } from 'lodash';
export function actionReport(module, actionDesc, extra) {
    delay(() => designerReport.report('action', {
        d1: module,
        d2: actionDesc,
        d3: stringifyOrErrorMsg(extra),
    }), 100);
}
export function createActionReport(module) {
    return (actionDesc, extra) => actionReport(module, actionDesc, extra);
}
