import { getMenuKey, isMenuDisabled } from '@/components/sidebar/utils';
import { MenuType } from '@/workbench/pages/project/navigation-menu/menu/type';
import { IconShow } from '@/components/icon-show';
import { createOpenApiWrap } from '@/reporter/openApiReport';
import { Module } from '@/reporter/type';
export const [sidebarOpenApiWrap, sidebarOpenApiBatchWrap] = createOpenApiWrap(Module.Sidebar);
export const isRootMenuKey = (key) => !key.includes('.');
export const getMenuItems = (menus) => {
    const withKey = (menus, parents = []) => {
        menus?.forEach((menu) => {
            const level = parents.length;
            const list = parents.concat(menu);
            const key = getMenuKey(list);
            Object.assign(menu, { level, key });
            withKey(menu.args.menus, list);
        });
    };
    withKey(menus);
    return menus;
};
const iconOpenMap = {
    folder: 'folder-open',
};
export function getMenuChildren(menus, { expandKeySet, showIcons, options, onClickMenu, }) {
    const getIcon = (m) => {
        if (!showIcons?.[m.level]) {
            return false;
        }
        const { icon } = m.args;
        if (expandKeySet.has(m.key)) {
            return iconOpenMap[icon] ?? icon;
        }
        return icon;
    };
    return menus?.map((m) => {
        const iconValue = getIcon(m);
        const icon = iconValue ? () => <IconShow value={iconValue}/> : undefined;
        if (m.type === MenuType.Menu) {
            if (m.args.menus?.length) {
                return (<t-submenu icon={icon} data-level={m.level} key={m.key} title={m.name} value={m.key}>
            {getMenuChildren(m.args.menus, { expandKeySet, showIcons, options, onClickMenu })}
          </t-submenu>);
            }
            return <t-menu-item icon={icon} data-level={m.level} key={m.key} value={m.key}>
        {m.name}
      </t-menu-item>;
        }
        const disabled = isMenuDisabled(m, options);
        return (<t-menu-item onClick={onClickMenu} icon={icon} disabled={disabled} data-level={m.level} key={m.key} value={m.key}>
        {m.name}
      </t-menu-item>);
    });
}
