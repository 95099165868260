var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import CRenderer from '@tencent/ui-core/lib/renderer/CRenderer';
import { walkLayout } from '@tencent/ui-core/lib/utils/index';
import UICoreXiaoyaoPlugin from '@components/uicorePlugin/UICoreXiaoyaoPlugin';
import Modal from '../modal/Modal.vue';
import styles from './styles.module.scss';
import { TAG_NAME, RENDERER_TAG_NAME, getPropId, SEPARATOR } from './utils';
import { PageletContext } from '@utils/global/api';
import { isRuntime } from '@/utils/globalInfo';
import { patchVueInstance } from '@tencent/ui-core/lib/utils/vue/patchVueInstance';
import { walkThroughEventStep } from '@utils/pagelet-related/layout';
const XyPageletComponentBase = Vue.extend({
    props: {
        pagelets: {
            type: Object,
            default: () => ([]),
        },
        slots: {
            type: Array,
            default: () => ([]),
        },
        compProp: {
            type: Object,
            default: () => ({}),
        },
    },
});
const stateTag = ['props', 'state'];
let XyPageletComponent = class XyPageletComponent extends XyPageletComponentBase {
    currentPagelets = [];
    modalController = {};
    paramsKeys = [];
    // uicorePlugin实例，每个页面的数据源会挂载在它上面
    pluginInstanceMap = {};
    pageletPropsMap = {};
    localW = new PageletContext({ mode: isRuntime() ? 'runtime' : 'editor', pageletContainer: this });
    // 弹窗页面
    get modalPagelets() {
        return this.currentPagelets.filter(pagelet => pagelet.xyType === 'modal');
    }
    // 主页面
    get defaultPagelet() {
        return this.currentPagelets[0];
    }
    get uiCoreInstance() {
        return this.ucRenderer ?? this.ucDesigner;
    }
    get dataSourceMap() {
        const map = {};
        Object.entries(this.pluginInstanceMap).forEach(([key, plugin]) => {
            map[key] = {
                data: plugin.getRootData(),
                dataSchema: plugin.getRootDataSchema(),
            };
        });
        return map;
    }
    get rootWContext() {
        let rootContext = this.wContext;
        while (rootContext.parentWContext && !['page', 'pagelet'].includes(rootContext.type)) {
            rootContext = rootContext.parentWContext;
        }
        return rootContext;
    }
    get defaultPageUcDataSource() {
        return this.getUcDataSourcesFromProps(this.defaultPagelet.id);
    }
    onDataSourceMapChange() {
        this.declareDataSourceInParentRenderer();
    }
    getBindings() {
        return this.$vnode.data?.['uc:layout']?.bindings;
    }
    created() {
        this.pageletPropSchemas.forEach((group) => {
            group.fields.forEach((field) => {
                this.paramsKeys.push(getPropId(field));
            });
        });
        this.initLayout();
        this.paramsKeys.forEach((key) => {
            this.$watch(() => this.$props[key], val => this.handlePropChange(key, val));
            const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
            const pagelet = this.currentPagelets.find(pagelet => pagelet.id === pageletId);
            if (tag === 'state') {
                const stateConfig = pagelet?.stateSchema?.fields.find(i => i.id === paramsKey);
                if (stateConfig)
                    this.$set(stateConfig, 'default', this.$props[key]);
            }
        });
        this.initModalController();
        this.buildPageletParamsMap();
    }
    mounted() {
        this.initSlot();
        this.declareDataSourceInParentRenderer();
        const rendererInstance = this.getCRendererInstance('default');
        // 组件联动方法
        this.exposedMethods?.forEach((key) => {
            // @ts-ignore
            this[key] = rendererInstance[key];
        });
        this.localW.renderer = rendererInstance;
        this.localW.pageInfo = this.w.pageInfo;
    }
    beforeMount() {
        const hookPageletComponentPatch = this.uiCoreInstance.uc.hooks.pageletRendererBeforeCreate;
        const pageletComponentPatchHandler = (cRenderer) => {
            const pageId = cRenderer.$attrs['pagelet-component-pageId'];
            const uid = cRenderer.$attrs['pagelet-component-uid'];
            if (pageId && uid === this._uid) {
                const pluginInstance = this.initPluginInstanceForPagelet(pageId);
                pluginInstance.patchCRenderer(cRenderer, pluginInstance);
                patchVueInstance(cRenderer, {
                    mounted: () => {
                        this.watchPageletState.bind(this)(pageId);
                    },
                });
            }
        };
        hookPageletComponentPatch.tap('pageletComponentPatch', pageletComponentPatchHandler);
        this.$once('hook:beforeDestroy', () => {
            hookPageletComponentPatch.taps = hookPageletComponentPatch.taps
                .filter((tap) => tap.fn !== pageletComponentPatchHandler);
        });
    }
    beforeDestroy() {
        this.$delete(this.ucRenderer.ucDataSources, `componentDataSource-${String(this.$vnode.key)}`);
        delete this.ucRenderer[`componentDataSource-${String(this.$vnode.key)}`];
        this.ucRenderer.ucRecomposeFullDataSchema();
    }
    getCRendererInstance(pageId) {
        return this.$refs[`${pageId}-renderer`];
    }
    buildPageletParamsMap() {
        this.paramsKeys.forEach((key) => {
            const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
            if (tag === 'state') {
                this.pageletPropsMap[pageletId] = this.pageletPropsMap[pageletId] || [];
                this.pageletPropsMap[pageletId].push({
                    stateKey: paramsKey,
                    propKey: key,
                });
            }
        });
    }
    watchPageletState(pageletId) {
        const paramsKey = this.pageletPropsMap[pageletId];
        if (!paramsKey)
            return;
        const cRenderer = this.getCRendererInstance(pageletId);
        paramsKey.forEach(({ stateKey, propKey }) => {
            const { state } = cRenderer;
            state[stateKey] = this.$props[propKey];
            this.$watch(() => state[stateKey], (val) => {
                this.$emit(`update:${pageletId}-${stateKey}`, val);
            });
        });
    }
    handlePropChange(key, val) {
        const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
        const cRenderer = this.getCRendererInstance(pageletId);
        if (stateTag.includes(tag) && cRenderer) {
            this.$set(cRenderer.state, paramsKey, val);
        }
        else {
            this.handleDataSourceChange(pageletId, paramsKey, val);
        }
    }
    handleDataSourceChange(pageletId, sourceId, collectionId) {
        const pagelet = this.currentPagelets.find(pagelet => pagelet.id === pageletId);
        if (!pagelet)
            throw Error(`未知的页面${pageletId}`);
        this.replaceDataSource({ sourceId, collectionId }, pagelet);
        this.resetPluginDataSource(pageletId);
    }
    initPluginInstanceForPagelet(pageId, force = false) {
        // 弹窗的数据源需要在每次打开的时候重新初始化
        if (this.pluginInstanceMap[pageId] && !force)
            return this.pluginInstanceMap[pageId];
        const pluginInstance = new UICoreXiaoyaoPlugin({
            parent: this,
        });
        // 如果组件没有给当前页面某个数据源赋值，那么我们不要初始化这个页面的数据源
        // 不然会在初始化的过程中弹窗
        this.$set(this.pluginInstanceMap, pageId, pluginInstance);
        return pluginInstance;
    }
    declareDataSourceInParentRenderer() {
        const source = {
            id: `componentDataSource-${String(this.$vnode.key)}`,
            schema: {
                title: `组件内参数 - ${String(this.$vnode.key)}`,
                fields: [],
                type: 'object',
            },
            instance: {},
        };
        const fields = [];
        const instance = {};
        // 只展示主页面的参数
        // 因为副页面在编辑器里没有实例化，拿不到数据源实例
        const pageSources = this.defaultPageUcDataSource;
        pageSources.forEach((source) => {
            const { id, schema, instance: pageInstance } = source;
            fields.push({
                id,
                ...schema,
            });
            instance[id] = pageInstance;
        });
        source.schema.fields = fields;
        source.instance = instance;
        this.ucRenderer?.addDataSource(source);
    }
    // 刷新页面数据源
    resetPluginDataSource(pageletId) {
        if (!this.pluginInstanceMap[pageletId])
            return;
        const cRenderer = this.getCRendererInstance(pageletId);
        if (!cRenderer)
            return;
        this.pluginInstanceMap[pageletId].initDataSources(cRenderer);
    }
    initModalController() {
        let pageletStr = JSON.stringify(this.currentPagelets);
        // 初始化弹窗控制器
        this.modalPagelets.forEach((page) => {
            const modalId = this.getModalId(page.id);
            this.$set(this.modalController, modalId, false);
            this.$set(this.rootWContext.childrenData || {}, modalId, { visible: false, data: {}, params: [] });
            pageletStr = pageletStr.replaceAll(`w.showModal('${page.id}')`, `w.showModal('${modalId}')`);
        });
        this.currentPagelets = JSON.parse(pageletStr);
        this.currentPagelets.forEach((page) => {
            // 将对应的modalID替换成这个在线组件专属的id
            walkLayout(page.layout, (item) => {
                if (item.layout.events) {
                    walkThroughEventStep(item.layout.events, (step) => {
                        if (step.type === 'xy:showModal') {
                            step.params.modalId = this.getModalId(step.params.modalId);
                        }
                    }, {
                        branchIncluded: true,
                    });
                }
            }, { includeScopedSlots: true });
        });
    }
    // 将弹窗的id包一层在线组件的标记
    getModalId(id) {
        return `pagelet-component-${this._uid}-${id}`;
    }
    initLayout() {
        const replaceTargetMap = this.getReplaceTargetMap();
        this.currentPagelets = this.replacePagelet(replaceTargetMap);
    }
    getReplaceTargetMap() {
        const replaceTargetMap = {};
        // 处理数据源
        const handleDataSource = (sourceId, collectionId, pageletId) => {
            if (collectionId === '')
                return;
            const currentPageMap = replaceTargetMap[pageletId] || {
                state: [],
                data: [],
            };
            currentPageMap.data.push({
                sourceId,
                collectionId,
            });
            replaceTargetMap[pageletId] = currentPageMap;
        };
        this.paramsKeys.forEach((key) => {
            const [paramsKey, tag, pageletId] = key.split(SEPARATOR);
            if (['props', 'state'].includes(tag)) {
                return;
            }
            const newProp = this.$props[key];
            handleDataSource(paramsKey, newProp, pageletId);
        });
        return replaceTargetMap;
    }
    // 替换参数
    replacePagelet(replaceTargetMap) {
        const newPagelets = cloneDeep(this.pagelets);
        Object.keys(replaceTargetMap).forEach((pageletId) => {
            const curPageletIndex = newPagelets.findIndex(item => item.id === pageletId);
            const currentPageTarget = replaceTargetMap[pageletId];
            // 替换页面数据源
            const dataSourceTypeTarget = currentPageTarget.data || [];
            const pagelet = newPagelets[curPageletIndex];
            dataSourceTypeTarget.forEach((target) => {
                this.replaceDataSource(target, pagelet);
            });
            newPagelets[curPageletIndex] = pagelet;
        });
        return newPagelets;
    }
    replaceDataSource(target, pagelet) {
        const { xySources } = pagelet;
        const { sourceId, collectionId } = target;
        const source = xySources.find(source => source.id === sourceId);
        if (source)
            source.collectionId = collectionId;
    }
    initSlot() {
        this.slots.forEach((slot) => {
            const el = this.$el.querySelector(`#${slot.id}`);
            if (el) {
                this.$refs[slot.id] = el;
            }
        });
    }
    renderSlot(id, placeHolder = '') {
        return <div id={id}>
      {this.$slots?.[id] || this.renderPlaceholder(placeHolder)}
    </div>;
    }
    getSlotStyle(id) {
        return this.slots.find(item => item.id === id)?.style || {};
    }
    renderPlaceholder(placeHolder) {
        return <div class={styles.placeHolderContainer}>
      {placeHolder || '请将组件拖到此处'}
    </div>;
    }
    getUcDataSourcesFromProps(pageId) {
        const dataSource = [];
        const xyDataSource = this.dataSourceMap[pageId];
        if (xyDataSource)
            dataSource.push({
                id: 'data',
                instance: xyDataSource.data,
                schema: {
                    ...xyDataSource.dataSchema,
                    title: '组件内数据源',
                },
            });
        if (pageId !== 'default') {
            const pagelet = this.pagelets.find(item => item.id === pageId);
            // 将弹窗的props添加上去
            dataSource.push({
                id: 'props',
                schema: pagelet.propsSchema || {},
                instance: this.rootWContext.childrenData[this.getModalId(pagelet.id)].data,
            });
        }
        return dataSource;
    }
    handleModalAfterClose(modalId) {
        setTimeout(() => this.wContext.pageletInstance.$emit(`afterDialogClose:${modalId}`), 0);
    }
    render() {
        // tag是用来给事件冒泡定位容器元素的
        return <div data-tag={TAG_NAME}>
      <CRenderer data-tag={RENDERER_TAG_NAME} pagelet-component-pageId={this.defaultPagelet.id} pagelet-component-uid={this._uid} ref={`${this.defaultPagelet.id}-renderer`} uc={this.uiCoreInstance.uc} uc-initial-pagelet={this.currentPagelets[0]} uc-internal-is-root={false} uc-data-sources-from-props={this.defaultPageUcDataSource} class="uc-renderer" style="padding: 0"></CRenderer>
      {this.modalPagelets.map((pagelet) => {
                const modalId = this.getModalId(pagelet.id);
                return <Modal id={this.getModalId(pagelet.id)} visible={this.rootWContext.childrenData[modalId].visible} merge-params={this.rootWContext.childrenData[modalId].mergeParams} params={this.rootWContext.childrenData[modalId].data} rendererType="cRenderer" pagelets={[{
                            ...pagelet,
                            id: modalId,
                        }]} {...{
                    on: {
                        'after-close': () => this.handleModalAfterClose(modalId),
                    },
                }}><CRenderer data-tag={RENDERER_TAG_NAME} pagelet-component-pageId={pagelet.id} pagelet-component-uid={this._uid} ref={`${pagelet.id}-renderer`} uc={this.uiCoreInstance.uc} uc-data-sources-from-props={this.getUcDataSourcesFromProps(pagelet.id)} uc-initial-pagelet={pagelet} uc-internal-is-root={false} class="uc-renderer"/>
          </Modal>;
            })}
    </div>;
    }
};
__decorate([
    Watch('dataSourceMap')
], XyPageletComponent.prototype, "onDataSourceMapChange", null);
XyPageletComponent = __decorate([
    Component({
        name: 'XyPageletComponent',
        inject: {
            wContext: { from: 'wContext', default: null },
            ucRenderer: { from: 'uicore:renderer', default: null },
            ucDesigner: { from: 'uicore:designer', default: null },
            w: { from: 'w', default: null },
        },
        provide() {
            return {
                pageletComponent: this,
                w: this.localW,
            };
        },
    })
], XyPageletComponent);
export default XyPageletComponent;
