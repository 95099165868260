import { notification as AntdNotification, Modal } from 'ant-design-vue';
import { overwriteAntdMessage } from '@utils/message';
import wujiMessage from './message';
overwriteAntdMessage();
/**
 * [update] 2025.2.10 - 在workbench路径下，使用TDesign的message和notification
 *
 * 我们需要设计一个message和notification方法，它挂载在vue的原型链上，并实现以下要求
 *
 * ## 不会被用户引入的ui框架篡改
 * 1. 原型链上的方法不会被用户引入的ui框架篡改，以确保我们的代码中this.$message的用法是安全的
 * 2. message和notification的参数以antd 1.x的message和notification为准
 *
 * ## 可通过暴露的api进行覆盖
 * 需要提供修改message具体实现的能力，方便我们通过插件来修改message的实现，比如切换为tdesign的message
 *
 * ## 兼容element-ui
 * 有部分项目会使用element-ui来进行开发，element-ui和antd间同名的有message和confirm，我们需要兼容这两个方法的调用
 */
var MessageType;
(function (MessageType) {
    MessageType["message"] = "message";
    MessageType["notification"] = "notification";
    MessageType["confirm"] = "confirm";
})(MessageType || (MessageType = {}));
export class WujiMessageManager {
    message;
    notification;
    confirm;
    proxyMap = new WeakMap();
    priority = {
        message: 0,
        notification: 0,
    };
    constructor(message, notification, confirm) {
        this.message = message;
        this.notification = notification;
        this.confirm = confirm;
    }
    /**
     * 设置vue.prototype.$message[key]
     * @param key message的key
     * @param message message方法
     * @param priority 优先级，优先级低于当前优先级的message不会被设置
     */
    setMessage(key, message, priority = 0) {
        if (priority < this.priority.message) {
            return;
        }
        this.message[key] = message;
        this.priority.message = priority;
    }
    setNotification(notification, priority = 0) {
        if (priority < this.priority.notification) {
            return;
        }
        this.notification = notification;
        this.priority.notification = priority;
    }
    getMethod(key) {
        if (this.proxyMap.has(this[key])) {
            return this.proxyMap.get(this[key]);
        }
        const proxy = new Proxy(this[key], {
            get(target, key) {
                return target[key];
            },
            set() {
                // 防止prototype.$message.xxx被修改
                return true;
            },
        });
        this.proxyMap.set(this[key], proxy);
        return proxy;
    }
}
export function wujiConfirm(...args) {
    const [message, title, options] = args;
    if (typeof message === 'string') {
        if (!options) {
            Modal.confirm({
                content: message,
                ...title,
            });
            return;
        }
        Modal.confirm({
            title,
            content: message,
            ...options,
        });
    }
    else {
        Modal.confirm(message);
    }
}
const wujiMessageManager = new WujiMessageManager(wujiMessage, AntdNotification, wujiConfirm);
window._wuji_message_manager = wujiMessageManager;
const defineImmutableMessage = (vueProto, key) => {
    Object.defineProperty(vueProto, `$${key}`, {
        get() {
            return wujiMessageManager.getMethod(key);
        },
        set() {
            // 不允许修改prototype中的$message和$notification
        },
    });
};
export default {
    install(vue, _options) {
        const { prototype } = vue;
        defineImmutableMessage(prototype, MessageType.message);
        defineImmutableMessage(prototype, MessageType.notification);
        defineImmutableMessage(prototype, MessageType.confirm);
    },
};
/**
 * 对于非.vue文件，或不方便从上下文中获取vue原型链上的message方法的场景，请使用下面的方法
 */
export const message = wujiMessageManager.getMethod(MessageType.message);
export const confirm = wujiMessageManager.getMethod(MessageType.confirm);
