import store from '@/store';
export function getSidebarInstance() {
    return store.state.layout.sidebar;
}
const matchPage = (comp, pageId) => {
    const { pages } = comp;
    let { visible } = comp;
    if (!visible)
        visible = pages?.length ? 'include' : 'exclude';
    if (visible === 'all')
        return true; // 所有
    if (visible === 'exclude')
        return !pages?.includes(pageId); // 隐藏
    return pages?.includes(pageId); // 显示
};
export const willRenderSomeComp = (comps, pageId) => comps.filter(c => c.isShow && matchPage(c, pageId)).length;
