import { stringifyOrErrorMsg } from '@/utils/object';
import { delay, isFunction } from 'lodash';
import { ReportContext } from './reportContext';
const ctx = new ReportContext();
export function openApiReport({ identity, module, args, ret, error, }) {
    try {
        window.w?.report({
            registeredEvent: 'openApi',
            d1: module,
            d2: identity,
            d3: window.w?.projectInfo?.componentHubUILibrary,
            d4: stringifyOrErrorMsg(args),
            d5: stringifyOrErrorMsg(ret),
            d6: String(error),
        });
    }
    catch (err) {
        console.error(err);
    }
}
export function funcWarp(func, hooks) {
    const t = ((...args) => {
        let ret = undefined;
        let error = undefined;
        hooks?.beforeRun?.();
        try {
            ret = func(...args);
        }
        catch (err) {
            error = err;
        }
        hooks?.afterRun?.(error, ret, args);
        if (error) {
            throw error;
        }
        return ret;
    });
    return t;
}
export function openApiWarp(module, identity, func) {
    return funcWarp(func, {
        beforeRun: () => ctx.enterReport(),
        afterRun(error, ret, args) {
            ctx.leaveReport();
            if (ctx.shouldReport()) {
                delay(() => {
                    Promise.resolve(ret).then((data) => {
                        openApiReport({ identity, module, args, ret: data, error });
                    }, (err) => {
                        openApiReport({ identity, module, args, ret: undefined, error: err });
                    });
                }, 100);
            }
        }
    });
}
export function createOpenApiWrap(module) {
    const t = (...args) => openApiWarp(module, ...args);
    const wrap = t;
    const batchWrap = (obj, keyPrefix) => {
        const ret = { ...obj };
        Object.keys(obj).forEach((key) => {
            const val = obj[key];
            if (isFunction(val)) {
                Object.assign(ret, { [key]: wrap(keyPrefix ? `${keyPrefix}.${key}` : key, val) });
            }
        });
        return ret;
    };
    return [wrap, batchWrap];
}
