<template>
  <div
    v-show="isShowLayout"
    id="xy-runtime-layout"
  >
    <!-- 导航 -->
    <template v-if="isShowNav">
      <Nav
        v-if="renderNavId === 'default'"
        id="xy-runtime-header"
        :mode="mode"
        :style="headerStyle"
      />
      <CustomNav
        v-else
        id="xy-runtime-header"
        :key="`${appConfig.id}-${renderNavId}`"
        :project="appConfig"
        :page-id="renderNavId"
        :style="headerStyle"
      />
    </template>
    <template v-if="isShowMiniProgramNav">
      <TDMobileNav v-if="isTDesign" />
      <MiniProgramNav v-else />
    </template>
    <div
      id="xy-runtime-wrap"
      ref="xy-runtime-wrap"
      :style="wrapStyle"
    >
      <a-alert
        v-if="isMockAsGeneralUser"
        class="mock-user-warning"
        message="你正在体验非管理员角色"
        type="warning"
        show-icon
      />
      <!-- 菜单 -->
      <template v-if="!isHideSidebar">
        <template v-if="renderSidebarId === 'default'">
          <TDSideBar v-if="isTDesign" />
          <Sidebar
            v-else
            id="xy-runtime-sidebar"
            :class="{ miniProgram: isMobileOrNarrowScreen }"
            :mode="mode"
            :style="sidebarStyle"
            :is-mobile-or-narrow-screen="isMobileOrNarrowScreen"
          />
        </template>
        <CustomSidebar
          v-else
          id="xy-runtime-sidebar"
          :key="`${appConfig.id}-${renderSidebarId}`"
          :class="{miniProgram:isMobileOrNarrowScreen}"
          :project="appConfig"
          :page-id="renderSidebarId"
          :width="sidebarWidth"
          :is-mobile-or-narrow-screen="isMobileOrNarrowScreen"
          :style="sidebarStyle"
        />
      </template>
      <!-- 页面: 页头 + 内容 + 页脚 -->
      <div
        :id="ELE_ID_RUNTIME_CONTENT"
        :class="{ 'header-scroll-hide': headerScrollHide}"
        :style="contentStyle"
      >
        <DevToolEntry :env="env" />
        <!-- 页头 -->
        <a-affix
          v-if="pageHeaderCount > 0 || showPageHeader"
          :target="affixTarget"
          class="affix-container"
        >
          <keep-alive>
            <CustomHeader
              v-if="renderHeaderId"
              :key="`${appConfig.id}-${renderHeaderId}`"
              class="custom-header"
              :project="appConfig"
              :render-id="renderHeaderId"
            />
          </keep-alive>
          <div
            v-if="showPageHeader"
            style="background-color: #fff;"
          >
            <div
              ref="pageHeader"
              class="page-header"
            >
              <Breadcrumb v-if="showBreadcrumb" />
              <TagsView v-if="showTagsView" />
            </div>
          </div>
        </a-affix>
        <!-- 内容 -->
        <keep-alive :max="10">
          <router-view
            v-if="$route.meta.isKeepAlive"
            class="xy-runtime-main-pagelet"
          />
        </keep-alive>
        <router-view
          v-if="!$route.meta.isKeepAlive"
          class="xy-runtime-main-pagelet"
        />
        <!-- 页脚 -->
        <CustomFooter
          v-if="renderFooterId"
          id="xy-runtime-footer"
          :key="`${appConfig.id}-${renderFooterId}`"
          :project="appConfig"
          :page-id="renderFooterId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, inject, watch } from '@vue/composition-api';
import { mapState } from 'vuex';
import { get } from 'lodash';

import Nav from '@components/nav/index';
import CustomNav from '@components/nav/CustomNav';
import CustomSidebar from '@components/sidebar/custom-sidebar';
import CustomHeader from '@components/header/custom-header';
import CustomFooter from '@components/footer/custom-footer';
import Sidebar from '@components/sidebar/index';
import { getBizEntry } from '@components/nav-widget/switch/utils';
import { TDSideBar } from '@/components/sidebar/tdesign';
import { checkBizMenusVisibility } from '@components/sidebar/utils';
import DevToolEntry from '@components/uicorePlugin/DevToolEntry';
import MiniProgramNav from '@miniprogram/MiniProgramNav';
import { TDMobileNav } from '@/miniprogram/mobile-nav';
import TagsView from '@/components/tags-view';
import Breadcrumb from '@/components/breadcrumb';
import { useStore } from '@store';
import { DEFAULT_ENV } from '@store/env';
import { useRouter } from '@/router/useRouter';
import { renderWatermark } from '@/components/watermark/utils';
import { isMobileOrNarrowScreen } from '@utils/screen';
import { isMockAsGeneralUser } from '@utils/userInfo';
import { ELE_ID_RUNTIME_CONTENT, getPageContainer } from '@utils/browser';
import { PAGE_TYPE } from '@/loaders/page/consts';
import { UILibrary } from '@/consts/ui-library';


export default {
  name: 'DefaultLayout',
  components: {
    Nav,
    CustomNav,
    Sidebar,
    TDSideBar,
    TDMobileNav,
    CustomSidebar,
    CustomHeader,
    CustomFooter,
    DevToolEntry,
    MiniProgramNav,
    Breadcrumb,
    TagsView,
  },
  props: {
    appConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    mode: {
      type: String,
      default: 'runtime', // 'editor'(编辑时) | 'runtime'(运行时) | 'preview'(预览时)
    },
    env: {
      type: String,
      default: 'dev',
    },
  },
  setup(props) {
    const store = useStore();
    const ctx = inject('ctx', null);
    const w = inject('w', null);
    const { appConfig: project } = toRefs(props);
    const { route } = useRouter();

    watch(() => {
      const biz = getBizEntry(project.value);
      return {
        isAdmin: ctx?.user?.isAdmin ?? false,
        roles: (ctx?.user?.roles ?? []),
        biz: biz?.value ?? '*',
      };
    }, ({ isAdmin, roles, biz }) => {
      store.commit('layout/setIsAdmin', isAdmin);
      store.commit('layout/setRoles', roles);
      store.commit('layout/setBiz', biz);
    }, { immediate: true });

    const pageAttr = computed(() => route.value?.meta?.pageAttr ?? {});
    const advanceConfig = project.value?.advanceConfig ?? {};

    // 渲染的导航ID
    const renderNavId = computed(() => {
      const pageNav = pageAttr.value.customNav;
      if (pageNav && pageNav !== 'system') return pageNav;
      const projectNav = advanceConfig?.customNav ?? 'default';
      return projectNav;
    });
    // 渲染的菜单ID
    const renderSidebarId = computed(() => {
      const pageSidebar = pageAttr.value.customSidebar;
      if (pageSidebar && pageSidebar !== 'system') return pageSidebar;
      const projectSidebar = advanceConfig?.customSidebar ?? 'default';
      return projectSidebar;
    });
    // 渲染的页头ID
    const renderHeaderId = computed(() => {
      const pageHeader = get(pageAttr.value, 'customHeader', 'system');
      if (pageHeader === 'system') {
        return get(advanceConfig, 'customHeader', '');
      }
      return pageHeader;
    });
    // 渲染的页脚ID
    const renderFooterId = computed(() => {
      const pageFooter = get(pageAttr.value, 'customFooter', 'system');
      if (pageFooter === 'system') {
        return get(advanceConfig, 'customFooter', '');
      }
      return pageFooter;
    });

    const isNavReady = computed(() => {
      if (renderNavId.value === 'default') {
        return true;
      }
      return !!store.state.layout.navRenderer;
    });

    const isSidebarReady = computed(() => {
      if (renderSidebarId.value === 'default') {
        return true;
      }
      return !!store.state.layout.sidebarRenderer;
    });

    const isPageFooterReady = computed(() => {
      if (renderFooterId.value) {
        return !!store.state.layout.pageFooterRenderer;
      }
      return true;
    });

    // 页头类型页面的数量
    const pageHeaderCount = computed(() => {
      const headers = store.state.runtime.basicPages.filter(p => p.pageType === PAGE_TYPE.HEADER);
      return headers?.length ?? 0;
    });

    const showBreadcrumb = computed(() => project.value?.uiInfo.navlink.navConfig.showBreadcrumb);
    const showTagsView = computed(() => project.value?.uiInfo.navlink.navConfig.showTagsView && !w?.isNarrowScreen);

    const isTDesign = computed(() => project.value?.componentHubUILibrary === UILibrary.tdesign);

    return {
      w,
      isTDesign,
      isNavReady,
      isSidebarReady,
      isPageFooterReady,
      pageAttr,
      renderNavId,
      renderSidebarId,
      renderHeaderId,
      renderFooterId,
      showBreadcrumb,
      showTagsView,
      pageHeaderCount,
    };
  },
  data() {
    return {
      ELE_ID_RUNTIME_CONTENT,
      isMockAsGeneralUser: isMockAsGeneralUser(),
      headerScrollHide: false,
      pageHeaderHeight: null,
    };
  },
  computed: {
    ...mapState({
      currentPage: state => state.runtime.currentPage,
    }),
    showPageHeader() {
      return (this.showBreadcrumb || this.showTagsView) && !!this.currentPage;
    },
    // 当需要渲染自定义布局时, 等待布局页面片加载完, 让渲染更加自然(会增加白屏时间)
    isShowLayout() {
      const nav = !this.isShowNav || this.isNavReady;
      const sidebar = this.isHideSidebar || this.isSidebarReady;
      const footer = this.isPageFooterReady;
      return nav && sidebar && footer;
    },
    isMobileOrNarrowScreen() {
      return isMobileOrNarrowScreen();
    },
    projectId() {
      return this.appConfig.id;
    },
    widgets() {
      let widgets = [];
      if (this.appConfig?.uiInfo?.navlink) {
        const { navlink } = this.appConfig.uiInfo;
        widgets = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey);
      }
      return widgets;
    },
    // 是否固定头部导航
    isNavFixTop() {
      let fixTop = false;
      // 页面配置
      const pageFixMode = this.pageAttr?.navFixTop;
      const projectFixMode = this?.appConfig?.uiInfo?.navlink?.navConfig?.navFixTop ?? false;
      if (typeof pageFixMode === 'string') {
        switch (pageFixMode) {
          case '':
          case 'system':
            fixTop = projectFixMode;
            break;
          case 'fix-top':
            fixTop = true;
            break;
          case 'not-fix-top':
            fixTop = false;
            break;
          default:
            fixTop = false;
            break;
        }
      } else if (typeof pageFixMode === 'boolean') {
        // TODO: [兼容] navFixTop 为 boolean 的情况
        fixTop = pageFixMode;
      } else {
        // TODO: [兼容] navFixTop 为其他值是跟随应用的值
        fixTop = projectFixMode;
      }
      return fixTop;
    },
    // 布局类型
    layoutType() {
      const meta = this.$route?.meta;
      if (!meta) return undefined;

      if (meta.pageType === 'pagelet') return 'content';  // 运行时，页面片永远不需要标题栏
      return meta.layoutType;
    },
    // 导航高度
    headerHeight() {
      if (!this.isShowNav && !this.isShowMiniProgramNav) return '0px';
      const height = this.pageAttr?.height ?? 56;
      if (typeof height === 'number') {
        return `${height}px`;
      }
      return height;
    },
    // 菜单宽度
    sidebarWidth() {
      const width = this.pageAttr?.width ?? 209;
      if (typeof width === 'number') {
        return `${width}px`;
      }
      return width;
    },
    isHideSidebarFromParent() {
      /** 审批流的 meta 不是直接定义在具体页面的路由的，而是定在上层 "/workflow" 的 */
      return this.$route.matched.some(r => r.meta?.layoutType && r.meta.layoutType.indexOf('sidebar') === -1);
    },
    // 是否隐藏系统头部
    isHideHeader() {
      const header = '_header';
      let isHide = window.$wujie?.props?.hideHeader
        || this.$route?.query?.[header] === '0'
        || this.$route?.query?.iframe === '1';
      if (!isHide) {
        isHide = this.layoutType?.indexOf('header') === -1;
      }
      return isHide;
    },
    // 是否隐藏侧边栏
    isHideSidebar() {
      // URL 的参数优先级更高
      const sidebar = '_sidebar';
      let isHide = window.$wujie?.props?.hideSidebar
        || this.$route?.query?.[sidebar] === '0'
        || this.$route?.query?.iframe === '1'
        || this.isHideSidebarFromParent
        || this.mode !== 'runtime';
      if (!isHide) {
        isHide = this.layoutType?.indexOf('sidebar') === -1;
      }
      return isHide;
    },
    isShowNav() {
      return !this.isHideHeader && !this.isMobileOrNarrowScreen;
    },
    isShowMiniProgramNav() {
      return this.isMobileOrNarrowScreen && !(this.isHideHeader && this.isHideSidebar);
    },
    // 是否隐藏调试入口
    isHideConsole() {
      const devtool = '_devtool';
      const isHide = this.$route?.query?.[devtool] === '0';
      return isHide;
    },
    wrapStyle() {
      const height = `calc(100vh - ${this.headerHeight})`;
      const style = {
        minHeight: height,
        '--runtime-height': height,
      };
      return style;
    },
    headerStyle() {
      return {
        height: this.headerHeight,
      };
    },
    sidebarStyle() {
      return {
        // width: this.sidebarWidth,
        height: this.isNavFixTop ? `calc(100vh - ${this.headerHeight})` : '100vh',
      };
    },
    contentStyle() {
      const style = {
        overflowY: 'auto',
        overflowX: 'hidden',
      };
      if (this.isNavFixTop || this.isHideHeader) {
        style.height = `calc(100vh - ${this.headerHeight})`;
        style.position = 'relative';
      }
      return style;
    },
    affixTarget() {
      return this.isNavFixTop ? this.getPageContainer : this.getWindowContainer;
    },
  },
  mounted() {
    this.$watch(
      () => this.isHideSidebar,
      () =>  this.$store.dispatch('navigation/setShowMenu', !this.isHideSidebar),
      { immediate: true },
    );
    const drawWaterMark = () => {
      if (this.mode === 'runtime' && this.env !== 'prod' && this.appConfig?.uiInfo?.navlink?.navConfig?.showNavWaterMark) {
        const fillStyle = this.appConfig?.uiInfo?.navStyle?.nav ? 'rgba(230, 230, 230, 0.2)' : 'rgba(230, 230, 230, 0.6)';
        renderWatermark({
          id: 'xy-runtime-header',
          content: DEFAULT_ENV[this.env] || '非正式环境',
          container: document.querySelector('#xy-runtime-header'),
          position: 'relative',
          fontSize: '18',
          fillStyle,
          rotate: 30,
          paddingX: 50,
          paddingY: 5,
          ignoreSize: true,
          disableEvent: false,
        });
      }
    };
    this.$watch(
      () => ([this.isShowNav, this.renderSidebarId]),
      // 自定义导航暂时不管
      () => this.isShowNav && this.renderSidebarId === 'default' && drawWaterMark(),
      { immediate: true },
    );
  },
  created() {
    // 校验当前路径是否为不可见的菜单项
    const groups = this.appConfig?.uiInfo?.sidebar ?? [];
    if (groups?.length > 0) {
      const { biz } = this.$store.state.layout;

      checkBizMenusVisibility({
        biz,
        groups,
        $router: this.$router,
        $route: this.$route,
        stringInteropContext: {
          ...(this.w?.mainRenderer ?? {}),
          $app: this.$app,
          w: this.w,
        },
      });
    }

    // 把当前实例挂载在全局供事件行为使用
    window.xy_runtime_default_layout_instance = this;
  },
  methods: {
    getPageContainer() {
      return getPageContainer();
    },
    getWindowContainer() {
      return window;
    },
  },
};
</script>
<style lang="scss">
@import './styles.scss';
</style>
