import { stagingEnvs } from '@config/constant';
import { message } from 'ant-design-vue';
import EnvService from '@/services/env';

// 不允许用户使用的环境
const EXCLUDE_ENVS = stagingEnvs;

const DEV = { id: 'dev', env: 'dev', name: '开发环境', envId: 'dev', tag: null };

const DEFAULT_ENVS =  {
  dev: '开发环境',
  test: '测试环境',
  prod: '正式环境',
};
stagingEnvs.forEach((env) => {
  DEFAULT_ENVS[env] = '预发布环境';
});

export const DEFAULT_ENV = DEFAULT_ENVS;

export default {
  state: {
    envList: [],
  },
  getters: {
    devInfo: () => DEV,
    envListWithDev(state) {
      return [DEV, ...state.envList];
    },
  },
  actions: {
    async fetchEnvList({ commit, rootState }, { projectId } = {}) {
      try {
        const projectID = projectId || rootState.project.projectInfo?.id;
        const result = await EnvService.fetchEnvList(projectID);
        if (result) {
          commit('updateEnvList', result.filter(item => !EXCLUDE_ENVS.includes(item.id)));
        }
        return result;
      } catch (err) {
        console.error(err);
        message.error('获取应用环境列表失败', err.message);
      }
    },
    async createEnv({ commit, state }, { projectId, env, name, disableSync, branch, tag }) {
      try {
        if ([...Object.keys(DEFAULT_ENV), 'branch'].includes(env)) {
          throw new Error('该环境已被系统使用，请设置另外一个环境id');
        }
        const createResult = await EnvService.createEnv({ projectId, env, name, disableSync, branch, tag });
        commit('updateEnvList', [...state.envList, createResult]);
        return createResult;
      } catch (err) {
        throw err;
      }
    },
    async updateEnv({ commit, state }, { projectId, env, name, disableSync, tag }) {
      try {
        const updateResult = await EnvService.updateEnv({ projectId, env, name, disableSync, tag });
        const target = state.envList.find(item => item.env === env);
        if (target) {
          target.name = name;
          target.disableSync = disableSync;
          target.tag = tag;
        }
        commit('updateEnvList', state.envList);
        return updateResult;
      } catch (e) {
        throw e;
      }
    },
    async deleteEnv({ commit, state }, { projectId, env }) {
      try {
        await EnvService.deleteEnv({ projectId, env });
        commit('updateEnvList', state.envList.filter(item => item.env !== env));
      } catch (e) {
        throw e;
      }
    },
  },
  mutations: {
    updateEnvList(state, newList) {
      state.envList = newList;
    },
  },
};
