import { useVariable } from '@/hooks/useVariable';
import { computed, ref } from '@vue/composition-api';
import { delay } from 'lodash';
// t-select creatable 模式回车和失焦时自动创建选项并关闭浮窗
export function useBetterCreatableSelect({ onCreate, hostRef }) {
    const innerSelectRef = ref();
    const selectRef = computed(() => hostRef?.value ?? innerSelectRef.value);
    const inputVal = useVariable();
    const onInputChange = (value) => {
        const val = String(value);
        inputVal.set(val);
    };
    const execCreate = (inputValue, e) => {
        onCreate(inputValue);
        selectRef.value?.setInnerPopupVisible(false, { e });
        inputVal.set(undefined);
    };
    const onEnter = ({ e, inputValue }) => {
        // 如果回车时t-select没有关闭面板，则自动保存并关闭下拉弹窗
        delay(() => {
            if (inputVal.get()) {
                execCreate(inputValue, e);
            }
        }, 100);
    };
    const onBlur = ({ e }) => {
        const inputValue = inputVal.get();
        if (inputValue) {
            execCreate(inputValue, e);
        }
    };
    return {
        onBlur,
        onEnter,
        onInputChange,
        selectRef: innerSelectRef,
    };
}
