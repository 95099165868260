import { computed, defineComponent } from '@vue/composition-api';
import { isMobileOrNarrowScreen } from '@/utils/screen';
import { WindowSideBar } from './window';
import { MobileSideBar } from './mobile';
export const TDSideBar = defineComponent({
    name: 'TDSideBar',
    setup() {
        const isMobile = computed(isMobileOrNarrowScreen);
        return { isMobile };
    },
    render() {
        if (this.isMobile)
            return (<MobileSideBar />);
        return <WindowSideBar />;
    },
});
