export var SchemaType;
(function (SchemaType) {
    SchemaType[SchemaType["Normal"] = 0] = "Normal";
    SchemaType[SchemaType["Indivisible"] = 1] = "Indivisible";
})(SchemaType || (SchemaType = {}));
export var Lang;
(function (Lang) {
    Lang["Javascript"] = "javascript";
    Lang["Html"] = "html";
})(Lang || (Lang = {}));
export var FieldType;
(function (FieldType) {
    FieldType[FieldType["Normal"] = 0] = "Normal";
    FieldType[FieldType["Array"] = 1] = "Array";
    FieldType[FieldType["DynamicObject"] = 2] = "DynamicObject";
    FieldType[FieldType["Indivisible"] = 3] = "Indivisible";
})(FieldType || (FieldType = {}));
export const defaultSchema = { getIdentity: identity => `（${identity}）` };
