import { defineComponent, ref } from '@vue/composition-api';
import classNames from 'classnames';
import { iconSourceSet } from './const';
import styles from './index.module.scss';
// 用于在编辑端替换public-w-icon-show-new（避免app锁组件版本导致出现异常）
export const IconShow = defineComponent({
    name: 'IconShow',
    props: {
        value: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: 'outlined',
        },
        color: {
            type: String,
            default: '',
        },
        hoverAble: {
            type: Boolean,
        },
        hoverBgColor: {
            type: String,
            default: 'var(--td-bg-color-container-active)',
        },
    },
    emits: {
        click: (_e) => void 0,
    },
    computed: {
        globalIcons() {
            return window?.xy_runtime_project?.advanceConfig?.globalIcons;
        },
        icon() {
            return this.value?.trim();
        },
        svgIcon() {
            if (/^<svg[\s\S]*<\/svg>$/.test(this.icon))
                return { template: this.icon };
            if (!this.checkDefault && /^<svg[\s\S]*<\/svg>$/.test(this.globalIconValue))
                return { template: this.globalIconValue };
            return null;
        },
        httpIcon() {
            if (/^http[s]:\/\//.test(this.icon))
                return this.icon;
            if (!this.checkDefault && /^http[s]:\/\//.test(this.globalIconValue))
                return this.globalIconValue;
            return '';
        },
        checkDefault() {
            return iconSourceSet.has(this.icon);
        },
        globalIconValue() {
            return this.checkDefault ? '' : decodeURIComponent(this.globalIcons?.[this.icon]) ?? '';
        },
        httpComponent() {
            return { template: this.httpSvgString || '' };
        },
    },
    watch: {
        httpIcon: {
            handler(v) {
                if (v) {
                    this.getHttpTemplate(v);
                }
                else {
                    this.httpSvgString = '';
                }
            },
            immediate: true,
        },
    },
    methods: {
        clickHandler(e) {
            this.$emit('click', e);
        },
        getHttpTemplate(link) {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', link, true);
            xhr.responseType = 'blob';
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = xhr.response;
                    const reader = new FileReader();
                    reader.onload = () => {
                        const svgString = reader.result;
                        this.httpSvgString = this.formatSvgString(svgString);
                    };
                    reader.readAsText(blob);
                }
            };
            xhr.send();
        },
        formatSvgString(svgString) {
            const regex = /<svg\b[^>]*>.*?<\/svg>/s;
            const match = svgString?.match(regex);
            return match?.[0] ?? '';
        },
    },
    setup() {
        const httpSvgString = ref('');
        return {
            httpSvgString,
        };
    },
    render() {
        const svgMode = !this.httpIcon && (this.icon || this.svgIcon);
        const style = { '--hover-bg-color': this.hoverBgColor, color: this.color };
        if (svgMode) {
            return (<a-icon data-icon-value={this.value} class={classNames(styles.icon, {
                    [styles.hoverAble]: this.hoverAble,
                })} type={this.icon} component={this.svgIcon} theme={this.theme} style={style} onClick={this.clickHandler}/>);
        }
        const httpMode = this.icon && this.httpSvgString;
        if (httpMode) {
            return (<a-icon data-icon-value={this.value} class={classNames(styles.icon, {
                    [styles.hoverAble]: this.hoverAble,
                })} component={this.httpComponent} style={{ ...style, width: '1em', height: '1em' }} onClick={this.clickHandler}/>);
        }
        return <span></span>;
    },
});
