import { defineComponent, computed, toRefs } from '@vue/composition-api';
import dayjs from 'dayjs';
const RelativeTime = defineComponent({
    name: 'RelativeTime',
    props: {
        time: {
            type: String,
            default: '',
        },
        noTooltip: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { time } = toRefs(props);
        const relativeTime = computed(() => dayjs(time.value).fromNow());
        const formatTime = computed(() => dayjs(time.value).format('YYYY-MM-DD HH:mm:ss'));
        return {
            relativeTime,
            formatTime,
        };
    },
    render() {
        if (this.noTooltip) {
            return <span>{this.relativeTime}</span>;
        }
        // @ts-ignore
        if (Object.keys(window.vue?.options?.components).includes('TTooltip')) {
            return (<t-tooltip content={this.formatTime}>
        {this.relativeTime}
      </t-tooltip>);
        }
        return (<a-tooltip title={this.formatTime}>
        {this.relativeTime}
      </a-tooltip>);
    },
});
export default RelativeTime;
