import { useRouter } from '@/router/useRouter';
import { onMounted, ref } from '@vue/composition-api';
export function useRouteStack() {
    const { router } = useRouter();
    const goBack = () => {
        router.value?.back();
    };
    const goHome = () => {
        router.value?.replace({ path: '/' });
    };
    // 简易的路由堆栈
    const routeStack = ref([]);
    onMounted(() => {
        router.value?.afterEach((to, from) => {
            const topRoute = routeStack.value[routeStack.value.length - 1];
            if (from?.fullPath && from.fullPath !== to?.fullPath) {
                if (to.fullPath === topRoute) {
                    routeStack.value.pop();
                }
                else {
                    routeStack.value.push(from.fullPath);
                }
            }
        });
    });
    return { routeStack, goBack, goHome };
}
