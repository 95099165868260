import { walkLayout } from '@tencent/ui-core/lib/utils';
import { EVENT_TYPE, SLOT_COMPONENT_TYPE } from './consts';
import { getCurrentUser } from '@/utils/rtx';
import { walkThroughEventStep } from '@utils/pagelet-related/layout';
export const getComponentId = (materialId, componentInfo) => {
    const group = componentInfo ? componentInfo.group : '';
    const id = componentInfo ? getFullId(componentInfo.id, group === 'default' ? '' : group) : `local-${materialId}`;
    return id;
};
export const getFullId = (id, group) => {
    const part = ['local'];
    if (group) {
        part.push(group);
    }
    part.push(id);
    return part.join('-');
};
export const handleInputChange = (e, target, key) => {
    const { value } = e.target;
    target[key] = value;
};
export const getDefaultComponentHubInfo = () => ({
    id: '',
    name: '',
    category: '',
    icon: '',
    changelog: '',
    owner: getCurrentUser(),
    version: '0.0.0',
    group: '',
    fitData: '',
});
export const isInComponentEditor = () => window.location.pathname.indexOf('project/editor/component') > -1;
export const getSlotsAndEvents = (baseData) => {
    const slots = [];
    const events = [];
    const gatherSlot = (layout) => {
        const { slotId } = layout.props || { slotId: 'default' };
        slots.push({
            id: slotId || 'default',
            containerRef: slotId || 'default',
            slotStyle: layout.style || {},
        });
    };
    const gatherEvent = (event) => {
        walkThroughEventStep(event, (step, _context) => {
            if (step.type !== EVENT_TYPE)
                return;
            if (events.find(event => event.id === step.params.eventKey))
                return;
            events.push({
                id: step.params.eventKey,
                name: step.params.eventName,
                arguments: [{
                        type: 'any',
                        description: step.params.eventDesc,
                    }],
            });
        }, {
            branchIncluded: true,
        });
    };
    baseData?.pageConfig.forEach((pageConfig) => {
        walkLayout(pageConfig.layout, (item) => {
            if (item.layout.type === SLOT_COMPONENT_TYPE) {
                gatherSlot(item.layout);
            }
            if (item.layout.events) {
                gatherEvent(item.layout.events);
            }
        }, {
            includeScopedSlots: true,
        });
        if (pageConfig.triggers) {
            pageConfig.triggers.forEach((trigger) => {
                if (!trigger.events)
                    return;
                Object.keys(trigger.events).forEach((key) => {
                    gatherEvent(trigger.events[key]);
                });
            });
        }
    });
    return {
        slots,
        events,
    };
};
