import { omit } from 'lodash';
import wujiFetch, { memoizedFetchV2 } from '@utils/wujiFetch';
import { BASE_API_PATH } from '@config/constant';
import { isRuntime } from '@utils/globalInfo';

const baseUrl = `${BASE_API_PATH}/xy/env`;
const basicBaseUrl = `${BASE_API_PATH}/xy/basic/env`;

const fetchEnvList = async (projectId) => {
  const urlPath = isRuntime() ? basicBaseUrl : baseUrl;
  return await memoizedFetchV2(`${urlPath}?projectid=${projectId}`, { method: 'GET', timeout: 3000 });
};

const getEnv = async (projectId, env) => await wujiFetch(`${baseUrl}/${env}?projectid=${projectId}`);

const createEnv = async (data) => {
  const { projectId } = data;
  return await wujiFetch(
    `${baseUrl}?projectid=${projectId}`,
    {
      method: 'POST',
      body: JSON.stringify(omit(data, 'projectId')),
    },
  );
};

const updateEnv = async (data) => {
  const { projectId, env, name, disableSync, tag } = data;
  return await wujiFetch(
    `${baseUrl}/patch/${env}?projectid=${projectId}`,
    {
      method: 'POST',
      body: JSON.stringify({
        name,
        disableSync,
        tag,
      }),
    },
  );
};

const deleteEnv = async ({ projectId, env }) => await wujiFetch(
  `${baseUrl}/delete/${env}?projectid=${projectId}`,
  {
    method: 'POST',
  },
);

export default {
  fetchEnvList,
  getEnv,
  createEnv,
  updateEnv,
  deleteEnv,
};
