import { computed, defineComponent, ref } from '@vue/composition-api';
import styles from './index.module.scss';
import useLayout from '@/composables/useLayout';
import classNames from 'classnames';
import { getSidebarInstance, willRenderSomeComp } from './utils';
import { useRouteStack } from './hooks/useRouteStack';
import NavComps from '@components/nav/NavComps';
import EnvSelect from '@components/nav/NavEnvSelect';
import NavWidget from '@components/nav-widget/NavWidget';
import { useRouter } from '@/router/useRouter';
import { isMiniprogram } from '@utils/screen';
import BackToHomePage from '@miniprogram/header/back-to-homepage.vue';
const ENV = 'env';
export const TDMobileNav = defineComponent({
    name: 'TDMobileNav',
    setup() {
        const { route } = useRouter();
        const { navTheme, project } = useLayout();
        const { routeStack, goBack, goHome } = useRouteStack();
        const collapsed = computed(() => getSidebarInstance()?.collapsed);
        const showEnvSelect = computed(() => {
            const { navlink } = project.value?.uiInfo || {};
            if (navlink?.defaultNav) {
                return navlink.defaultNav.find(item => item.id === ENV)?.isShow;
            }
            return false;
        });
        const envShowList = computed(() => {
            const envConfig = project.value?.uiInfo?.navlink?.defaultNav.find(item => item.id === ENV);
            return envConfig?.showList ?? ['*'];
        });
        const envRedirect = computed(() => {
            const envConfig = project.value?.uiInfo?.navlink?.defaultNav.find(item => item.id === ENV);
            return envConfig?.redirect ?? 'same';
        });
        const showComp = computed(() => willRenderSomeComp(project.value.uiInfo.navlink.dynamicComps, route.value?.meta?.pageId));
        const widgets = computed(() => {
            let ret = [];
            if (project.value?.uiInfo?.navlink) {
                const { navlink } = project.value.uiInfo;
                ret = (navlink.widgets ?? []).filter(w => !!w.type && w.config?.queryKey && w.isShow);
            }
            return ret;
        });
        const onMenuIconClick = () => {
            getSidebarInstance()?.innerToggleCollapsed();
        };
        const showDrawerOpener = computed(() => Boolean(widgets.value.length || showEnvSelect.value || showComp.value));
        const showDrawer = ref(false);
        const openDrawer = () => {
            getSidebarInstance()?.innerSetCollapsed(true);
            showDrawer.value = true;
        };
        const closeDrawer = () => {
            showDrawer.value = false;
        };
        return {
            navTheme,
            project,
            collapsed,
            routeStack,
            showDrawer,
            envShowList,
            envRedirect,
            showEnvSelect,
            widgets,
            showDrawerOpener,
            goBack,
            goHome,
            onMenuIconClick,
            openDrawer,
            closeDrawer,
        };
    },
    render() {
        const { project, collapsed } = this;
        const menuIcon = collapsed ? 'view-list' : 'close';
        const showGoHome = this.routeStack.length === 0;
        const showGoBack = this.routeStack.length > 0;
        const isMini = isMiniprogram();
        return (<t-layout-header wj-mod="导航" class={classNames(styles.nav, this.navTheme)}>
        <div class={styles.left}>
          {isMini ? <BackToHomePage /> : [
                showGoBack && <t-icon onClick={this.goBack} class={classNames(styles.icon, styles.bigger)} name="chevron-left"/>,
                showGoHome && <t-icon onClick={this.goHome} class={styles.icon} name="home"/>,
            ]}
          <img class={styles.logo} src={project.logo}/>
          <div class={styles.name}>{project.name.repeat(20)}</div>
        </div>
        <div class={styles.right}>
          {this.showDrawerOpener && <t-icon class={styles.icon} onClick={this.openDrawer} name="menu-application"/>}
          <t-icon onClick={this.onMenuIconClick} class={classNames(styles.icon, {
                [styles.bigger]: !collapsed,
            })} name={menuIcon}/>
        </div>
        <t-drawer placement='top' visible={this.showDrawer} preventScrollThrough footer={false} header={false} class={classNames(styles.drawer, this.navTheme)} showOverlay={false}>
          <div class="flex justify-end"><t-icon onClick={this.closeDrawer} class={classNames(styles.icon, styles.bigger)} name="close"/></div>

          <div class={'mt-4'}>
            <NavComps ref="dynamic-comps" project={project} position={'left'}/>
            <NavComps ref="dynamic-comps" project={project} position={'right'}/>
          </div>
          {this.showEnvSelect && <div class="flex items-center mt-4">
            <span class={styles.label}>环境</span>
            <EnvSelect showList={this.envShowList} redirect={this.envRedirect}/>
          </div>}
          {Boolean(this.widgets.length) && <div class="flex items-center mt-4">
            <span class={styles.label}>业务</span>
            <NavWidget projectId={project.id} widgets={this.widgets} isMobileOrNarrowScreen={true} hideWidgets={!!project.uiInfo?.navlink?.hideWidgets}/>
          </div>}
        </t-drawer>
      </t-layout-header>);
    },
});
