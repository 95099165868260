import { getDefaultGroup, getMatchedGroupId } from '@/components/sidebar/utils';
import useLayout from '@/composables/useLayout';
import { runtimeInfo } from '@/utils/fetch';
import logger from '@/utils/logger';
import { shallowObj } from '@/utils/object';
import { defineContext } from '@/utils/vue/context';
import { computed, inject } from '@vue/composition-api';
import { cloneDeep, isMatch, noop } from 'lodash';
import qs from 'query-string';
import { Mode } from '../type';
import { getMenuItems } from '../util';
import { useSyncToStore } from './useSyncToStore';
import { useRouter } from '@/router/useRouter';
function useContext({ formatter }) {
    const wLayout = inject('wLayout');
    const w = inject('w', {});
    const routerData = useRouter();
    const { router: $router } = routerData;
    const { visibleSidebarGroups, sidebarTheme, biz, roles, project, isAdmin, visibleGroupMap, curGroup } = useLayout();
    const $route = computed(() => {
        const $route = routerData.route.value;
        const $router = routerData.router.value;
        let finalRoute = $route;
        try {
            // 无权限时根据重定向的 url 字段模拟路由选中菜单
            const { page403Path, page403Query: page403QueryStr } = runtimeInfo();
            const currentQuery = $route?.query || {};
            const page403Query = qs.parse(page403QueryStr)?.query || {};
            if ($route?.path === page403Path && $route?.query?.url && isMatch(currentQuery, page403Query)) {
                const url = new URL(currentQuery.url);
                const base = $router?.options?.base;
                const path = url.href.replace(url.origin, '').replace(base, '');
                const mockRoute = $router?.resolve(path);
                if (mockRoute?.resolved) {
                    finalRoute = mockRoute.route;
                }
            }
        }
        catch (err) {
            logger.error('菜单获取路由失败', err);
        }
        finally {
            return finalRoute;
        }
    });
    const stringInteropContext = computed(() => ({
        ...(w.mainRenderer ?? {}),
        $app: w.$app,
        w,
    }));
    const options = computed(() => shallowObj({ stringInteropContext, $route, $router }));
    const getGroupId = () => getMatchedGroupId(visibleSidebarGroups.value, options.value) || getDefaultGroup()?.groupId;
    const groupId = computed(getGroupId);
    const sidebar = computed(() => {
        // 触发依赖收集
        noop(biz.value, roles.value, isAdmin.value);
        return formatter.sidebar({ groupId: groupId.value, sidebarMap: visibleGroupMap.value, origin: visibleSidebarGroups.value }) ?? {};
    });
    const menus = computed(() => getMenuItems(formatter.menus(cloneDeep(shallowObj({ sidebar })))));
    const menusMap = computed(() => {
        const map = {};
        const walk = (menus) => menus?.forEach((m) => {
            map[m.key] = m;
            walk(m.args.menus);
        });
        walk(menus.value);
        return map;
    });
    const getMenu = (val) => menusMap.value[String(val)];
    useSyncToStore(Mode.Runtime);
    const isDarkTheme = computed(() => sidebarTheme.value === 'dark');
    const currentGroupId = computed(() => curGroup.value.groupId);
    return { sidebar, wLayout, sidebarTheme, project, options, menus, getMenu, isDarkTheme, currentGroupId };
}
export const [provideSidebarContext, mountSidebarContext, getSidebarContext] = defineContext(useContext);
