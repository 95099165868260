import { getAvailableComponent } from '@/utils/comps-loader';

/**
 * @type {import('../types').PageGenerator}
 */
export const flowDetailPage = function ({ flowId }, { projectId, pageId }) {
  return {
    getPageConfig() {
      const pagelet = {
        id: 'default',
        name: 'mainPage',
        ucVersion: 2,
        xyType: 'pagelet',
        lessCode: '',
        layout: {
          type: 'w-container',
          style: {
            padding: '20px',
          },
          inlineStyleProps: [
            '+padding',
          ],
          children: [
            {
              type: 'w-paragraph',
              children: [
                {
                  type: 'public-w-readonly-new',
                  style: {
                    fontSize: '24px',
                    marginRight: '8px',
                  },
                  componentId: 'publicWReadonlyNew',
                  props: {},
                  bindings: {
                    'v-model': 'job.title',
                  },
                },
                {
                  type: 'w-tag',
                  componentId: 'wTag',
                  props: {},
                  bindings: {
                    标签内容: 'job.status',
                  },
                },
                getJobReloadButton(),
              ],
              props: {
                align: 'left',
              },
              componentId: 'wParagraph2',
              slots: {
                '0-title': [
                  {
                    type: 'w-paragraph',
                    children: [
                      {
                        type: 'public-w-readonly-new',
                        style: {
                          height: '22px',
                          lineHeight: '22px',
                          'word-wrap': 'break-word',
                        },
                        componentId: 'publicWReadonlyNew',
                        props: {
                          value: '未命名折叠面板',
                        },
                      },
                    ],
                    props: {
                      align: 'left',
                    },
                    componentId: 'wParagraph',
                  },
                ],
              },
            },
            {
              type: 'w-paragraph',
              children: [
                {
                  type: 'markdown',
                  props: {
                    source: '流程单ID: `{{ job.id }}` / 网址',
                  },
                  componentId: 'markdown',
                },
                {
                  type: 'public-w-a-link',
                  componentId: 'publicWALink',
                  props: {
                    text: '{{ job.url }}',
                  },
                  bindings: {
                    'v-model': 'job.url',
                  },
                  style: {
                    textOverflow: 'ellipsis',
                    width: '286px',
                    '-webkit-line-clamp': '1',
                    height: '1em',
                    lineHeight: '1',
                    overflow: 'hidden',
                  },
                },
                {
                  type: getAvailableComponent('public', 'w-button'),
                  style: {
                    fontSize: '14px',
                  },
                  componentId: 'publicWButtonNew',
                  props: {
                    icon: 'copy',
                    type: 'default',
                    size: 'small',
                    btnText: '复制',
                  },
                  events: {
                    ':handleClick': {
                      steps: [
                        {
                          type: 'uicore:copyText',
                          params: {
                            copyValue: '{{ job.url }}',
                            isRemind: true,
                          },
                          _key: 'lefcmutk',
                        },
                      ],
                    },
                  },
                },
              ],
              props: {
                align: 'left',
              },
              componentId: 'wParagraph',
              renderCondition: 'job.id // 新建流程单时不展示',
            },
            {
              type: 'w-paragraph',
              children: [
                {
                  type: 'markdown',
                  props: {
                    source: '当前审批人为：{{ job.currentApprovers || \'...\' }}',
                  },
                  componentId: 'markdown2',
                },
                {
                  type: getAvailableComponent('public', 'w-button'),
                  style: {
                    fontSize: '14px',
                  },
                  componentId: 'publicWButtonNew2',
                  props: {
                    type: 'default',
                    size: 'small',
                    btnText: '复制信息 + 一键拉群',
                    icon: 'phone',
                  },
                  events: {
                    ':handleClick': {
                      steps: [
                        {
                          type: 'uicore:copyText',
                          params: {
                            copyValue: '您好，请留意审批单据《{{ job.title }}》\n\n{{ job.url }}',
                            isRemind: true,
                          },
                          _key: 'lefcmutk',
                        },
                        {
                          type: 'xyEvent:wxwork:message',
                          params: {
                            messageConfig: {
                              type: 'usernameExpr',
                              username: '',
                              usernameExpr: '{{job.currentApprovers}}',
                              uin: '',
                            },
                          },
                          _key: 'lefclrpp',
                        },
                      ],
                    },
                  },
                },
              ],
              props: {
                align: 'left',
              },
              componentId: 'wParagraph3',
              renderCondition: 'job.currentApprovers',
            },
            {
              type: 'w-array',
              bindings: {
                数据源: 'job.detailBlocks',
              },
              props: {
                columnCount: 3,
                layoutMode: 'lines',
              },
              scopedSlots: {
                item: {
                  layout: {
                    type: 'w-container',
                    children: [
                      {
                        type: 'public-w-accordion-2v',
                        props: {
                          expanded: {
                            $expr: 'data.index === 0 || data.index === data.list.length - 1',
                          },
                          notRenderWhenClose: true,
                          smoothHeightTransition: true,
                        },
                        extraStyle: {
                          '&.w-collapse-group-container .ant-collapse .ant-collapse-item .ant-collapse-header': {
                            '': {
                              backgroundColor: '#FBFBFB',
                              color: 'rgba(0,0,0,0.85)',
                            },
                          },
                          '&.w-collapse-group-container .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow': {
                            '': {
                              color: 'rgba(0,0,0,0.85)',
                            },
                          },
                          '&.appended': {
                            '': {
                              animation: 'floatUp 0.5s ease-out forwards',
                            },
                          },
                        },
                        componentId: 'publicWAccordion2v',
                        slots: {
                          '0-title': [
                            {
                              type: 'w-paragraph',
                              children: [
                                {
                                  type: 'public-w-readonly-new',
                                  style: {
                                    height: '22px',
                                    lineHeight: '22px',
                                    'word-wrap': 'break-word',
                                  },
                                  componentId: 'publicWReadonlyNew',
                                  props: {
                                    value: '未命名折叠面板',
                                  },
                                  bindings: {
                                    'v-model': 'data.item.title',
                                  },
                                },
                              ],
                              props: {
                                align: 'left',
                              },
                              componentId: 'wParagraph',
                            },
                          ],
                          '0-content': [
                            {
                              type: 'xy-page-container',
                              style: {
                                'min-height': '60px',
                              },
                              props: {
                                type: 'flowNodePagelet',
                                flowNodePageletConfig: {
                                  $expr: '{\n  "flowId": parentScope.job.flowId,\n  "nodeId": data.item.nodeId,\n}',
                                },
                                params: [
                                  {
                                    key: 'jobId',
                                    value: '{{ parentScope.job.id }}',
                                  },
                                  {
                                    key: 'startTickId',
                                    value: '{{data.item.startTickId}}',
                                  },
                                ],
                                hideLoadingIndicator: true,
                                projectId,
                              },
                              componentId: 'xyPageContainer2',
                            },
                          ],
                        },
                        class: ' {{data.item.appended? \'appended\': \'\'}}',
                      },
                    ],
                  },
                },
              },
              componentId: 'wArray',
            },
            {
              type: 'w-paragraph',
              componentId: 'wParagraph4',
              children: [
                {
                  type: 'public-w-spin',
                  componentId: 'publicWSpin',
                  props: {
                    icon: 'default',
                    spinTip: '流程运行中',
                    textPosition: 'right',
                  },

                },
              ],
              props: {
              },
              renderCondition: 'job.isWaitingForNextBlock',
            },
          ],
        },
        triggers: [
          {
            type: 'uc-timer',
            componentId: 'ucTimer',
            props: {
              interval: 10,
            },
            events: {
              ':handleTick': {
                steps: [
                  {
                    type: 'uicore:runScript',
                    params: {
                      script: 'if(job.isWaitingForNextBlock && document.visibilityState !=="hidden") job.reload()',
                    },
                    _key: 'lj2is0ty',
                  },
                ],
              },
            },
          },
        ],
      };

      return {
        projectId,
        pageId,
        title: '流程: {{ job.title }}',
        pageData: {},
        pageConfig: [pagelet],
      };
    },
    getPluginList() {
      const pluginId = 'internal:w-flow-page-plugin';
      const id = [projectId, pageId, pluginId].join(':');
      return [
        {
          _id: id,
          id,
          pluginId,
          projectId,
          pageId,
          pluginConfig: JSON.stringify({
            flowId,
            pageType: 'layout',
          }),
          detail: {},
        },
      ];
    },
  };
};
export const getJobReloadButton = () => ({
  type: getAvailableComponent('public', 'w-button'),
  style: {
    fontSize: '14px',
    marginLeft: 'auto',
  },
  componentId: 'publicWButtonNew2',
  props: {
    type: 'default',
    btnText: '刷新',
    icon: 'redo',
  },
  events: {
    ':handleClick': {
      steps: [
        {
          type: 'uicore:runScript',
          params: {
            script: 'await job.reload();',
          },
          _key: 'lf17ak7z',
        },
      ],
    },
  },
});
