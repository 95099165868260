var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.menus.length || !_vm.isRuntime || _vm.isMobileOrNarrowScreen)?_c('a-layout-sider',{class:[
    ("xy-sidebar xy-default-sidebar " + _vm.sidebarTheme),
    {
      'collapsed': _vm.collapsed,
      'runtime': _vm.isRuntime,
      'miniProgram': _vm.isMobileOrNarrowScreen,
    }
  ],attrs:{"width":_vm.computedWidth,"theme":_vm.sidebarTheme,"collapsible":_vm.isRuntime,"trigger":null,"collapsed-width":"0"},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[(_vm.isMobileOrNarrowScreen)?[_c('MiniprogramHeader',{class:{
        'header-hidden': _vm.collapsed
      },attrs:{"mode":_vm.mode}}),_vm._v(" "),_c('div',{staticClass:"drawer-mask",class:{'drawer-mask-opened' : !_vm.collapsed},style:(_vm.maskLeft),on:{"click":function($event){return _vm.innerSetCollapsed(true)}}})]:[(_vm.withTitle)?[_c('div',{class:{ collapsed: _vm.collapsed },attrs:{"id":"xy-sidebar-title"}},[(_vm.project.logo)?_c('img',{staticClass:"xy-sidebar-logo",attrs:{"src":_vm.project.logo}}):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"xy-sidebar-text"},[_vm._v("\n          "+_vm._s(_vm.project.name)+"\n        ")])])]:_vm._e(),_vm._v(" "),(_vm.withMenuTitle)?[_c('div',{class:{collapsed:_vm.collapsed},attrs:{"id":"xy-sidebar-menu-title"}},[(_vm.sidebar.groupLogo)?_c('div',{staticClass:"xy-sidebar-logo"},[_c('public-w-icon-show-new',{staticClass:"logo-icon",attrs:{"value":_vm.sidebar.groupLogo}})],1):_vm._e(),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],staticClass:"xy-sidebar-text"},[_vm._v("\n          "+_vm._s(_vm.sidebar.groupName || '')+"\n        ")])])]:_vm._e(),_vm._v(" "),(_vm.withSearch)?[_c('div',{attrs:{"id":"xy-sidebar-menu-search"}},[_c('a-input',{attrs:{"placeholder":"搜索","allow-clear":""},on:{"change":_vm.handleSearch},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}},[_c('a-icon',{staticClass:"!opacity-80",attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1)]:_vm._e()],_vm._v(" "),_c('XySidebarMenu',{class:{
      'no-menu-title': !_vm.withMenuTitle,
      'hidden': _vm.collapsed
    },attrs:{"menus":_vm.searchInput ? _vm.searchMenus : _vm.menus,"selected-keys":_vm.selectedKeys,"open-keys":_vm.searchInput ? _vm.searchOpenKeys : _vm.openKeys,"show-icons":_vm.showIcons},on:{"openChange":_vm.handleMenuOpenChange,"click":_vm.handleMenuClick}}),_vm._v(" "),(!_vm.isMobileOrNarrowScreen)?[_c('div',{staticClass:"xy-sidebar-resizer",on:{"mousedown":_vm.startResize,"touchstart":_vm.startResize}}),_vm._v(" "),(_vm.isRuntime)?_c('CollapseTrigger',{attrs:{"collapsed":_vm.collapsed,"is-show-collapse-trigger":_vm.isShowCollapseTrigger},on:{"toggle-collapsed":_vm.innerToggleCollapsed}}):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.isIntranetWork)?[_c('div',{class:[("sidebar-identify " + _vm.sidebarTheme)],on:{"click":_vm.handleToWujiHome}},[_vm._v("\n      "+_vm._s(_vm.SIDEBAR_IDENTITY)+"\n    ")])]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }