<template>
  <div class="wrapper">
    <div class="header-container">
      <div class="title">
        <a-icon
          type="arrow-left"
          class="breadcrumb-icon"
          @click="goBack"
        />
        <span
          v-if="isNarrowScreen"
          style="margin-left:8px;line-height:28px"
        >{{ entity && entity.name }}</span>
        <h3
          v-else
          class="title"
        >
          {{ entity && entity.name }}
        </h3>
      </div>
      <div
        v-if="isNarrowScreen && (canAbort || canRestart)"
        class="operation"
      >
        <a-dropdown>
          <a
            class="ant-dropdown-link"
            @click="e => e.preventDefault()"
          >
            <a-icon type="more" />
          </a>
          <a-menu
            slot="overlay"
            @click="handleMenuClick"
          >
            <a-menu-item
              v-if="canAbort"
              key="abort"
            >
              撤销
            </a-menu-item>
            <a-menu-item
              v-if="canRetry"
              key="retry"
            >
              审批重试
            </a-menu-item>
            <a-menu-item
              v-if="canRestart"
              key="restart"
            >
              重新发起审批
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div
        v-if="!isNarrowScreen"
        class="operation"
      >
        <a-alert
          v-if="isProcessing && pluginDisableDefaultAction"
          show-icon
        >
          <span slot="message">
            <span>通知插件禁止默认的审批操作, 请审批人在「{{ pluginDisplayName }}」侧审批</span>
          </span>
        </a-alert>
        <a-button
          v-if="canAbort"
          @click="abort"
        >
          撤销
        </a-button>
        <a-button
          v-if="canRetry"
          style="margin-left:8px"
          @click="retry"
        >
          审批重试
        </a-button>
        <a-button
          v-if="canRestart"
          style="margin-left:8px"
          @click="restart"
        >
          重新发起审批
        </a-button>
      </div>
    </div>
    <w-sys-spin
      v-if="initializing"
      class="center"
    />
    <render-page
      v-else-if="pageId"
      style="height:100vh;background-color:#f8f8f8"
      :project-id="projectId"
      :page-id="pageId"
    />
    <detail
      v-else-if="entity"
      style="min-height:calc(100vh - 112px)"
      :project-id="projectId"
      :env="env"
      :entity="entity"
    />
    <div
      v-else
      class="p-4 flex-grow-1 bg-white bg-clip-content"
    >
      <a-alert
        class="m-4"
        type="warning"
        message="单据不存在或无权访问"
      />
    </div>
  </div>
</template>

<script>
import { Modal, message } from 'ant-design-vue';
import { uniq } from 'lodash';
import { getAppParams } from '@utils/path';
import { workflowApi, workflowEntityApi } from '@/services';
import RenderPage from './RenderPage.vue';
import Detail from './Detail.vue';
import { getUserId } from '@/utils/userInfo';
import { isMobileOrNarrowScreen } from '@utils/screen';
import { getWorkflowNotificationPluginList } from '@pages/modules/workflow/config/utils';

export default {
  components: {
    RenderPage,
    Detail,
  },
  inject: {
    ctx: 'ctx',
  },
  data() {
    return {
      pageId: '',
      initializing: true,
      entity: null,
      flowConf: null,
      rtx: getUserId(),
      records: [],
      retryRecord: null,
      comment: '',
    };
  },
  computed: {
    urlParams() {
      return getAppParams() || {};
    },
    projectId() {
      return this.urlParams.projectId;
    },
    env() {
      return this.urlParams.env || '';
    },
    id() {
      const { query } = this.$route;
      return query.entityid;
    },
    // 插件可以禁止默认的审批行为，全权交由第三方进行审批处理
    pluginDisableDefaultAction() {
      const pluginId = this.flowConf?.notifyPluginId;
      if (!pluginId) return false;
      const plugin = getWorkflowNotificationPluginList().find(x => x.id === pluginId);
      return !!plugin?.disableDefaultAction;
    },
    pluginDisplayName() {
      const pluginId = this.flowConf?.notifyPluginId;
      if (!pluginId) return '';
      const plugin = getWorkflowNotificationPluginList().find(x => x.id === pluginId);
      return plugin?.name ?? '';
    },
    isProcessing() {
      return this.entity?.flowStatus === 'processing';
    },
    canAbort() {
      if (this.flowConf?.disabledAbort || this.pluginDisableDefaultAction) return false;
      if (['success', 'fail', 'abort'].includes(this.entity?.flowStatus)) return false;
      return this.entity?.creator === this.rtx || this.ctx.user.isAdmin;
    },
    canRestart() {
      if (this.flowConf?.disabledRestart || this.pluginDisableDefaultAction) return false;
      return (this.entity?.creator === this.rtx || this.ctx.user.isAdmin);
    },
    canRetry() {
      const rec = this.retryRecord;
      if (!rec) return false;
      return (rec.creator === this.rtx || this.ctx.user.isAdmin);
    },
    isNarrowScreen() {
      return isMobileOrNarrowScreen();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const entity = await this.getWorkflowEntity();
      this.initializing = false;
      if (!entity) return;
      this.records = await this.getEntityRecords();
      this.entity = entity;
      this.flowConf = JSON.parse(entity.flowConf);
      this.retryRecord = this.getFailedRecord(this.flowConf, this.records);
      this.injectInCtx(entity, this.flowConf);
      const ids = this.getIds(entity);
      if (!ids) return;
      const { pageId } = ids;
      this.pageId = pageId;
    },
    injectInCtx(entity) {
      const payload = entity.payload ? JSON.parse(entity.payload) : null;
      this.ctx.workflowEntity = entity;
      this.ctx.workflowApprovalData = payload;
    },
    async getWorkflowEntity() {
      try {
        const result = await workflowEntityApi.fetchWorkflowEntity(this.projectId, this.env, this.id);
        return result;
      } catch (err) {
        message.error('获取审批单失败');
      }
    },
    async getEntityRecords() {
      try {
        const result = await workflowEntityApi.fetchEntityRecords(this.projectId, this.env, this.id);
        return result;
      } catch (err) {
        message.error('获取审批单记录失败');
      }
    },
    getIds(entity) {
      const { status, flowConf } = entity;
      const conf = JSON.parse(flowConf);
      const node = conf.nodes.find(node => node.id === status);
      if (!node.pageId) return null;
      const [projectId, pageId] = node.pageId.split(':');
      return { projectId, pageId };
    },
    handleMenuClick({ key }) {
      if (key === 'abort') return this.abort();
      if (key === 'restart') return this.restart();
      if (key === 'retry') return this.retry();
    },
    abort() {
      Modal.confirm({
        title: '确定撤销当前流程单？',
        content: h => h('div', null, [
          h('a-input', {
            style: { marginTop: '8px' },
            props: { placeholder: '说明' },
            on: { change: e => this.comment = e.target.value },
          }, null),
        ]),
        onOk: async () => {
          try {
            await workflowEntityApi.abort(this.projectId, this.env, this.id, { comment: this.comment });
            this.$router.go(0);
          } catch (error) {
            message.error(`撤单失败, ${error.message}`);
          }
        },
      });
    },
    getFailedRecord(flow, records) {
      const hasAbortedResp = (record) => {
        const check = (resp) => {
          const arr = resp ? JSON.parse(resp) : [];
          return arr.some(x => x.aborted);
        };
        return check(record.entryHookResponse) || check(record.afterHookResponse);
      };
      const isAbnormalNode = (nodeId) => {
        const node = flow.nodes.find(x => x.id === nodeId);
        return node?.abnormal;
      };
      // 失败节点列表
      const failedIdList = uniq(records.filter(x => x.operation === 'pass' && isAbnormalNode(x.to))
        .map(x => x.from)
        .filter(Boolean));
      // 已经重试成功的失败节点列表
      const retryNodeIdList = records.filter(x => x.operation === 'pass'
        && failedIdList.includes(x.from)
        && !isAbnormalNode(x.to)
        && !hasAbortedResp(x)).map(x => x.from);

      // 没有重试或重试仍然失败的节点
      const failedRecList = records.filter(x => x.operation === 'pass'
        && failedIdList.includes(x.from)
        && !isAbnormalNode(x.to)
        && hasAbortedResp(x)
        && !retryNodeIdList.includes(x.from));

      // 只能有一个
      return failedRecList.length ? failedRecList[0] : null;
    },
    retry() {
      Modal.confirm({
        title: '确定重试审批执行钩子失败的节点？',
        content: h => h('div', null, [
          h('p', null, '将会在失败节点上重新执行相同的审批操作，若节点上配置有多个钩子接口，无论之前已经执行成功或失败，都会再次执行'),
          h('p', null, `失败节点：${this.flowConf.nodes.find(x => x.id === this.retryRecord.from).name}(${this.retryRecord.from})`),
        ]),
        onOk: async () => {
          try {
            await workflowEntityApi.retry(this.projectId, this.env, this.id, this.retryRecord.from);
            this.$router.go(0);
          } catch (error) {
            const resp = await error.response?.json();
            message.error(`重试失败, ${resp?.error}`);
          }
        },
      });
    },
    async doRestart() {
      try {
        await workflowEntityApi.restart(this.projectId, this.env, this.id);
        this.$router.go(0);
      } catch (error) {
        message.error(`重新发起审批失败, ${error.message}`);
      }
    },
    async restart() {
      const { _id: id, flowId, flowConf, objectId, payload = '' } = this.entity;
      let flow = JSON.parse(flowConf);
      try {
        // 尝试使用新的流程配置来重新发起
        flow = await workflowApi.fetchWorkflow(this.projectId, this.env, flowId);
      } catch (error) {
        console.warn('没有找到审批配置');
      }
      // 使用`发起流程页面`来重新发起流程
      if (flow?.pageId) {
        const pageId = flow.pageId.replace(`${this.projectId}:`, '');
        this.$router.push({ name: pageId, query: { id: objectId, entityid: id, payload } });
        return;
      }

      Modal.confirm({
        title: '确定重新发起审批？',
        content: '检测到当前审批配置没有关联发起流程页面，无法修改审批数据，将用原审批数据重新发起',
        onOk: () => this.doRestart(),
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: calc(100vh - var(--xy-header-height, 56px));
  display: flex;
  flex-direction: column;
  flex: 1;

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 #EEEEEE;
  border-left: 1px solid #F0F2F5;
  padding-right: 24px;

  .title {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 20px;
      color: rgba(0,0,0,0.90);
      line-height: 28px;
      margin: 0;
      padding-left: 12px;
    }
  }
}

</style>
