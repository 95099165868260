export var DragPosition;
(function (DragPosition) {
    DragPosition[DragPosition["Before"] = -1] = "Before";
    DragPosition[DragPosition["Inside"] = 0] = "Inside";
    DragPosition[DragPosition["After"] = 1] = "After";
})(DragPosition || (DragPosition = {}));
export function setNodeState(tree, node, state) {
    node.value && tree?.setItem(node.value, state);
}
export function setNodeData(node, data) {
    node?.setData({ ...node.data, ...data });
}
