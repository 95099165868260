import { Lang } from '../type';
export const schema = {
    id: 'scf',
    name: '云函数',
    identityField: 'name',
    pickFields: ['source', 'body'],
    fields: {
        body: {
            name: '函数体（编译后）',
            lang: Lang.Javascript,
        },
        source: {
            name: '源代码（编译前）',
            lang: Lang.Javascript,
            diffPlaceholder: '将wuji-cli升级到最新版后，新创建的云函数在发版时可以查看源代码',
        },
    },
};
